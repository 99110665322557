<template>
  <div>
    <template v-for="(file, index) in files">
      <file-upload
        :key="index"
        v-model="files[index]"
        v-bind="$attrs"
        :name="name"
        :uploadOptions="uploadOptions"
        :readonly="readonly"
      />
      <br :key="`br_${index}`" />
    </template>
    <file-upload
      v-model="newFile"
      v-bind="$attrs"
      :name="name"
      :uploadOptions="uploadOptions"
      :readonly="readonly"
      :showEmpty="!files.length"
    />
  </div>
</template>

<script>
import fileUpload from './file-upload.vue';
//const deleteUrlProp = 'deleteEndpoint';

export default {
  components: { fileUpload },
  name: 'TextInput',
  $_veeValidate: {
    value() {
      //return this.$el.value;
      return this.file;
    },

    name() {
      return this.name;
    }
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },

    uploadOptions: {
      type: Object,
      required: false,
      default: () => {}
    },

    name: {
      type: String,
      required: false,
      default: ''
    },

    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    files: [],
    fileCount: 0,
    newFile: null
  }),

  watch: {
    value() {
      this.files = [...this.value];
      this.fileCount = this.files.length;
    },

    files: {
      handler() {
        const updatedFiles = this.files.filter((x) => x);
        if (updatedFiles.length != this.fileCount) {
          this.$emit('input', updatedFiles);
        }
      },
      deep: true
    },

    newFile() {
      if (!this.newFile) {
        return;
      }

      this.files.push(this.newFile);
      this.newFile = null;
    }
  },

  mounted() {
    this.files = [...this.value];
    this.fileCount = this.files.length;
  }
};
</script>

<style scoped>
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 1%;
  height: 2px;
  position: absolute;
  cursor: pointer;
}

.file-input-wrapper {
  display: inline-block;
}
</style>
