import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c('draggable',{attrs:{"options":{
      animation: 100,
      handle: '.drag-widget'
    }},on:{"end":_vm.updateIndex},model:{value:(_vm.widgets),callback:function ($$v) {_vm.widgets=$$v},expression:"widgets"}},[_c('transition-group',{staticClass:"row",attrs:{"name":"fade"}},[_vm._l((_vm.availableWidgets),function(widget){return [(widget.active)?_c(widget.type,{key:widget.name,tag:"component",staticClass:"dashboard-widget",attrs:{"size":widget.size,"processType":widget.processType},on:{"closed":_vm.closeWidget,"resized":_vm.resizeWidget}}):_vm._e()]})],2)],1),_c(VDialog,{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c(VFabTransition,[_c('hp-button',{attrs:{"primary":"","fixed":"","bottom":"","right":"","fab":"","button":"","on":on}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 lighten-2"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.addWidget'))+" ")]),_c(VDivider),_c(VList,{attrs:{"nav":"","dense":""}},[_c(VListItemGroup,{attrs:{"color":"primary"}},_vm._l((_vm.availableWidgets.filter(function (x) { return !x.active; })),function(widget){return _c(VListItem,{key:widget.name,on:{"click":function($event){return _vm.addWidget(widget)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(" "+_vm._s(widget.icon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(widget.name)+" ")])],1)],1)}),1),(!_vm.showAddWidget)?[_c('div',{staticClass:"muted text-center mb-4"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.noWidgetsAvailable'))+" ")])]:_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }