<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on: dialog, attrs }">
      <hp-button :on="{ ...dialog }" v-bind="attrs" primary>
        <slot name="button">
          <v-icon>mdi-plus</v-icon>
          {{ $t('employee.addEmployee') }}
        </slot>
      </hp-button>
    </template>

    <hp-widget icon="mdi-account-plus">
      <template v-slot:title>
        {{ $t('employee.addEmployee') }}
      </template>

      <template v-slot:actions>
        <hp-button plain icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </hp-button>
      </template>

      <v-form ref="employeeForm">
        <v-row>
          <v-col cols="12" class="mb-8 img-upload">
            <image-upload @change="setImage" />
          </v-col>
        </v-row>

        <v-text-field
          v-model="firstName"
          solo
          flat
          :label="$t('employeeDetails.firstName')"
        ></v-text-field>

        <v-text-field
          v-model="lastName"
          solo
          flat
          :label="$t('employeeDetails.lastName')"
        ></v-text-field>

        <v-text-field
          v-model="email"
          solo
          flat
          :label="$t('employeeDetails.email')"
          type="email"
        ></v-text-field>

        <v-text-field
          v-model="password"
          solo
          flat
          :label="$t('employeeDetails.password')"
          type="password"
        ></v-text-field>
      </v-form>

      <template v-slot:buttons>
        <hp-button @click="cancel">
          <slot name="close-button">
            <v-icon class="mr-2">mdi-close</v-icon>
            {{ $t('cancel') }}</slot
          >
        </hp-button>
        <hp-button primary @click="confirm">
          <slot name="confirm-button">
            <v-icon class="mr-2">mdi-check</v-icon>
            {{ $t('save') }}</slot
          >
        </hp-button>
      </template>

      <!-- <v-card-actions class="pa-4 pt-0">
        <v-spacer></v-spacer>
        
      </v-card-actions> -->
    </hp-widget>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import imageUpload from '@/components/misc/image-upload.vue';

export default {
  props: {
    onboarding: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    showDialog: false,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    image: null
  }),

  methods: {
    ...mapActions('employees', {
      createEmployee: 'createEmployee'
    }),

    cancel() {
      this.$refs.employeeForm.reset();
      this.showDialog = false;
    },

    async confirm() {
      await this.createEmployee({
        FirstName: this.firstName,
        LastName: this.lastName,
        Email: this.email,
        Password: this.password,
        Image: this.image,
        Onboarding: this.onboarding
      });

      this.showDialog = false;
    },

    setImage(selectedImage) {
      this.image = selectedImage;
    }
  },

  components: {
    imageUpload
  }
};
</script>
<style scoped>
.img-upload {
  display: flex;
  justify-content: center;
}

.image-upload__dropzone {
  position: static;
  display: flex;
  max-height: 200px;
  max-width: 200px;
  min-height: 200px;
  min-width: 200px;
}
</style>
