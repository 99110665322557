<template>
  <v-container class="pa-0">
    <v-row dense class="mt-2">
      <v-col
        v-for="(employee, index) in value"
        :key="employee.id"
        cols="3"
        class="d-flex"
      >
        <employee-widget
          v-model="value[index]"
          :layout="layout"
          :onboarding="onboarding"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <create-modal :onboarding="onboarding" />
      </v-col>
      <!--<v-col cols="12" class="text-right">
        <hp-button @click="importEmployees">
          <v-icon>mdi-help</v-icon>
            Auf gar keinen Fall drücken
          </hp-button>
      </v-col>-->
    </v-row>
  </v-container>
</template>

<script>
import employeeWidget from '@/components/employees/employeesOverview/employee-widget.vue';
import createModal from '@/components/employees/employeesOverview/create-employee.vue';
//import { mapActions } from 'vuex';

export default {
  props: {
    value: {
      type: Array,
      required: true
    },

    layout: {
      type: Object,
      required: true
    },

    onboarding: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  /*methods: {
    ...mapActions('employees', {
      importEmployees: 'importEmployees'
    })
  },*/

  components: {
    employeeWidget,
    createModal
  }
};
</script>
