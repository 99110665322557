<template>
  <router-link :to="to" tag="div" style="width: 100%">
    <hp-user-tile class="employee-overview__card">
      <template v-slot:title>
        {{ value.title }} {{ value.firstName }}
        {{ value.lastName }}
      </template>

      <div class="d-flex">
        <user-image v-if="value" v-model="value.picture" />

        <div v-if="layout.fields" class="d-block">
          <div
            v-for="field in layout.fields"
            :key="field.id"
            class="d-flex pb-1"
          >
            <v-icon>{{ field.icon }}</v-icon>
            <details-field v-model="value" :field="field"></details-field>
          </div>
        </div>
      </div>

      <v-divider class="mt-4 mb-4" />

      <div>
        <details-tag
          v-for="skill in value.skills"
          v-bind:value="skill"
          :key="`skill_${skill.skillId}`"
        >
          {{ getSkillTranslation(skill.skillId, skill.skillType) }}
        </details-tag>
      </div>
    </hp-user-tile>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';

import detailsField from '@/components/employees/employeeDetails/details-field.vue';
import userImage from '@/components/misc/user-image.vue';
import detailsTag from '@/components/employees/employeeDetails/details-tag.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    layout: {
      type: Object,
      required: true
    },

    onboarding: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      options: 'employees/getEmployeeDataOptions'
    }),

    to() {
      var prefix = this.onboarding ? 'onboarding' : 'employees';
      return `/${prefix}/${this.value.id}`;
    }
  },

  methods: {
    getSkillTranslation(skillId, skillType) {
      let options = [];
      switch (skillType) {
        case 1:
          options = this.options.languages;
          break;
        case 2:
        default:
          options = this.options.skills;
          break;
      }

      var translation = options.find((x) => x.id === skillId)?.translation;
      if (translation) {
        return this.localize(translation);
      }

      return '';
    }
  },

  components: {
    detailsField,
    userImage,
    detailsTag
  }
};
</script>
<style scoped>
.v-card__title,
.v-card__text .row {
  padding-bottom: 0px !important;
}

.v-card,
.v-card__text {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.v-card__text {
  padding-top: 0px !important;
}

.v-card:hover {
  cursor: pointer;
}
</style>
