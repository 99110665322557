<template>
  <v-snackbar v-model="visible" :color="color" :timeout="timeout">
    <v-layout align-center pr-4>
      <v-icon v-if="icon" class="pr-3" dark large>{{ icon }}</v-icon>
      <v-layout column>
        <div>{{ text }}</div>
      </v-layout>
    </v-layout>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Onboarding',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    timeout: {
      type: Number,
      required: false,
      default: 3000
    },
    color: {
      type: String,
      required: false,
      default: 'success'
    },
    icon: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      visible: false
    };
  },

  watch: {
    value() {
      this.visible = this.value;
    },

    visible() {
      this.$emit('input', this.visible);
    }
  },

  mounted() {
    this.visible = this.value;
  }
};
</script>
<style scoped>
.v-snack * {
  color: white;
}
</style>
