<template>
  <v-row
    v-if="fieldValue !== undefined"
    class="align-center"
    :class="[componentClass, fieldComponent]"
  >
    <v-col cols="12" sm="6" md="5" class="col-label" v-if="showName">
      {{ localize(field.translation) }}
    </v-col>
    <v-col cols="12" :sm="showName ? 6 : 12" :md="showName ? 7 : 12">
      <component
        :is="fieldComponent"
        v-model="fieldValue"
        :options="fieldOptions"
        :readonly="readonly"
        :employeeId="value.userId"
        solo
        flat
        hide-details
        enabled
      ></component>
    </v-col>
  </v-row>
</template>

<script>
import datepicker from '@/components/misc/datepicker.vue';
import datetimePicker from '@/components/misc/datetime-picker.vue';
import numericField from '@/components/misc/numeric-field.vue';
import currencyField from '@/components/misc/currency-field.vue';
import localizationTextField from '@/components/misc/localization-text-field.vue';
import localizationAutocomplete from '@/components/misc/localization-autocomplete.vue';
import singleLanguageTextField from '@/components/misc/single-language-text-field.vue';
import singleLanguageAutocomplete from '@/components/misc/single-language-autocomplete.vue';
import fileUpload from '@/components/misc/file-upload.vue';
import fileUploadMultiple from '@/components/misc/file-upload-multiple.vue';
import { mapGetters } from 'vuex';
import { layoutEnums } from '@/components/enums/layouts.js';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    field: {
      type: Object,
      required: true
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    },

    showName: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      fieldTypes: layoutEnums.fieldTypes,
      valueTypes: layoutEnums.valueTypes,
      fieldValue: undefined,
      fieldOptions: []
    };
  },

  computed: {
    ...mapGetters({
      languageKey: 'languageKey',
      options: 'employees/getEmployeeDataOptions'
    }),

    componentClass() {
      return !this.readonly ? 'editable' : 'readonly';
    },

    locationOptions() {
      return this.options?.locations ?? [];
    },

    nationOptions() {
      return this.options?.nations ?? [];
    },

    positionOptions() {
      return this.options?.positions ?? [];
    },

    hierarchyOptions() {
      return this.options?.hierarchies ?? [];
    },

    fieldComponent() {
      switch (this.field.valueType) {
        case this.valueTypes.MULTILINGUAL:
          return 'localization-text-field';
        case this.valueTypes.DATE:
          return 'datepicker';
        case this.valueTypes.DATETIME:
          return 'datetime-picker';
        case this.valueTypes.NUMERIC:
          return 'numeric-field';
        case this.valueTypes.CURRENCY:
          return 'currency-field';
        case this.valueTypes.MULTILINGUALMULTIPLEOPTIONS:
          return 'localization-autocomplete';
        case this.valueTypes.MULTIPLEOPTIONS:
          return 'single-language-autocomplete';
        case this.valueTypes.FILEUPLOADSINGLE:
          return 'file-upload';
        case this.valueTypes.FILEUPLOADMULTIPLE:
          return 'file-upload-multiple';
        case this.valueTypes.TEXT:
        default:
          return 'single-language-text-field';
      }
    },

    defaultFieldValue() {
      switch (this.field.valueType) {
        case this.valueTypes.FILEUPLOADSINGLE:
          return {};
        case this.valueTypes.MULTILINGUAL:
        case this.valueTypes.MULTILINGUALMULTIPLEOPTIONS:
        case this.valueTypes.FILEUPLOADMULTIPLE:
          return [];
        case this.valueTypes.TEXT:
        case this.valueTypes.DATE:
        case this.valueTypes.DATETIME:
        case this.valueTypes.NUMERIC:
        case this.valueTypes.CURRENCY:
        case this.valueTypes.MULTIPLEOPTIONS:
        default:
          return '';
      }
    }
  },

  async mounted() {
    this.setData();
  },

  watch: {
    fieldValue: {
      handler(newValue) {
        this.updateFieldValue(newValue);
      },
      deep: true
    },

    value: {
      handler() {
        this.setData();
      },
      deep: true
    }
  },

  methods: {
    setData() {
      switch (this.field.fieldType) {
        case this.fieldTypes.FIRSTNAME:
          this.fieldValue = this.value?.firstName;
          break;
        case this.fieldTypes.LASTNAME:
          this.fieldValue = this.value?.lastName;
          break;
        case this.fieldTypes.TITLE:
          this.fieldValue = this.value?.title;
          break;
        case this.fieldTypes.GENDER:
          this.fieldOptions = Object.keys(this.$t('genders')).map((x) => [
            {
              id: parseInt(x),
              languageKey: this.languageKey,
              translation: this.$t(`genders.${x}`)
            }
          ]);

          this.fieldValue = this.fieldOptions.find(
            (x) => x[0].id === this.value?.gender
          );
          break;
        case this.fieldTypes.EMAIL:
          this.fieldValue = this.value?.email;
          break;
        case this.fieldTypes.POSITION:
          this.fieldValue = this.value?.position?.translation;
          this.fieldOptions = this.positionOptions.map((x) => x.translation);
          break;
        case this.fieldTypes.HIERARCHY:
          this.fieldValue = this.value?.hierarchy?.translation;
          this.fieldOptions = this.hierarchyOptions.map((x) => x.translation);
          break;
        case this.fieldTypes.LOCATION:
          this.fieldValue = this.value?.location?.translation;
          this.fieldOptions = this.locationOptions.map((x) => x.translation);
          break;
        case this.fieldTypes.NATION:
          this.fieldValue = this.value?.nation?.translation;
          this.fieldOptions = this.nationOptions.map((x) => x.translation);
          break;
        case this.fieldTypes.EMPLOYMENTSTARTDATE:
          this.fieldValue = this.value?.employmentStartDate;
          break;
        case this.fieldTypes.SUPERVISOR:
          this.fieldValue = this.value?.supervisorName;
          break;
        case this.fieldTypes.CUSTOM:
          this.fieldValue = this.getCustomFieldValue();
          this.fieldOptions = this.getCustomFieldOptions();
          break;
      }
    },

    updateFieldValue(fieldValue) {
      if (!this.value) {
        return;
      }

      switch (this.field.fieldType) {
        case this.fieldTypes.FIRSTNAME:
          this.value.firstName = fieldValue;
          break;
        case this.fieldTypes.LASTNAME:
          this.value.lastName = fieldValue;
          break;
        case this.fieldTypes.TITLE:
          this.value.title = fieldValue;
          break;
        case this.fieldTypes.GENDER:
          this.value.gender = fieldValue.id ?? fieldValue[0].id;
          break;
        case this.fieldTypes.EMAIL:
          this.value.email = fieldValue;
          break;
        case this.fieldTypes.POSITION:
          this.value.position = this.getOption(
            this.positionOptions,
            fieldValue
          );
          break;
        case this.fieldTypes.HIERARCHY:
          this.value.hierarchy = this.getOption(
            this.hierarchyOptions,
            fieldValue
          );
          break;
        case this.fieldTypes.LOCATION:
          this.value.location = this.getOption(
            this.locationOptions,
            fieldValue
          );
          break;
        case this.fieldTypes.NATION:
          this.value.nation = this.getOption(this.nationOptions, fieldValue);
          break;
        case this.fieldTypes.EMPLOYMENTSTARTDATE:
          this.value.employmentStartDate = fieldValue;
          break;
        case this.fieldTypes.SUPERVISOR:
          this.value.supervisorName = fieldValue;
          break;
        case this.fieldTypes.CUSTOM:
          this.setCustomFieldValue(fieldValue);
          break;
      }
    },

    getOption(options, fieldValue) {
      if (fieldValue?.length === 0) {
        return this.defaultFieldValue;
      }

      return (
        options.find((x) => x.id === fieldValue[0].id) ?? this.defaultFieldValue
      );
    },

    getCustomFieldValue() {
      const customInfo = this.value?.customInformation?.find(
        (x) => x.customFieldId === this.field.id
      );

      if (customInfo?.value) {
        return JSON.parse(customInfo.value);
      }

      return this.defaultFieldValue;
    },

    setCustomFieldValue(fieldValue) {
      const customInfo = this.value?.customInformation?.find(
        (x) => x.customFieldId === this.field.id
      );

      const fieldValueString = JSON.stringify(fieldValue);
      if (customInfo) {
        customInfo.value = fieldValueString;
      } else if (this.value?.customInformation) {
        this.value.customInformation.push({
          customFieldId: this.field.id,
          userId: this.value.id,
          value: fieldValueString
        });
      }
    },

    getCustomFieldOptions() {
      return this.field.fieldOptions?.map((x) => x.translation) ?? [];
    }
  },

  components: {
    datepicker,
    datetimePicker,
    numericField,
    currencyField,
    localizationTextField,
    localizationAutocomplete,
    singleLanguageTextField,
    singleLanguageAutocomplete,
    fileUpload,
    fileUploadMultiple
  }
};
</script>
<style lang="scss" scoped>
.row.editable {
  padding-bottom: 12px;
}

.row.file-upload-multiple .col-label {
  align-self: baseline;
  padding-top: 20px;
}
</style>
