<template>
  <div>
    <hp-card class="employee-details">
      <v-tabs v-model="currentTab" align-with-title grow>
        <v-tabs-slider color="var(--v-primary-base)" />

        <template v-if="layout.tabs">
          <v-tab v-for="tab in layout.tabs" :key="tab.id">
            {{ localize(tab.translation) }}
          </v-tab>
        </template>
        <v-tab>
          {{ $t('skills.name') }}
        </v-tab>
        <!--<v-tab v-if="isAdmin">
          {{ $t('modules.name') }}
        </v-tab>-->
      </v-tabs>

      <v-tabs-items v-model="currentTab" v-if="layout.tabs">
        <template v-for="tab in layout.tabs">
          <employee-details-tab
            v-model="value"
            :tab="tab"
            :key="tab.id"
            :readonly="readonly"
          />
        </template>
        <skills-tab :readonly="readonly" v-model="value.skills" />
        <!--<details-modules-tab
          v-if="isAdmin"
          :readonly="readonly"
          :employee="value"
        />-->
      </v-tabs-items>
    </hp-card>
    <br />
    <hp-card class="flex-grow-1" v-if="!readonly" style="height: 100%">
      <v-row>
        <v-col cols="12" class="text-right">
          <delete-modal @delete="deleteEmployee" />
          <hp-button primary @click="save">
            <v-icon>mdi-content-save-outline</v-icon>{{ $t('save') }}
          </hp-button>
        </v-col>
      </v-row>
    </hp-card>
  </div>
</template>

<script>
import employeeDetailsTab from '@/components/employees/employeeDetails/details-tab.vue';
import skillsTab from '@/components/employees/employeeDetails/skills-tab.vue';
import deleteModal from '@/components/employees/employeeDetails/delete-employee.vue';
//import detailsModulesTab from '@/components/employees/employeeDetails/details-modules-tab.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    layout: {
      type: Object,
      required: true
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    },

    isAdmin: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      currentTab: null
    };
  },

  methods: {
    save() {
      this.$emit('save');
    },

    deleteEmployee() {
      this.$emit('delete');
    },

    setTab(tab) {
      this.currentTab = tab;
    }
  },

  components: {
    employeeDetailsTab,
    //detailsModulesTab,
    skillsTab,
    deleteModal
  }
};
</script>
