<template>
  <v-tab-item>
    <v-card
      v-if="tab.groupings"
      flat
      class="mt-1 py-8 employee-details__groups"
    >
      <v-card-text>
        <v-row>
          <v-col v-for="grouping in tab.groupings" :key="grouping.id">
            <details-grouping
              v-model="value"
              :grouping="grouping"
              :readonly="readonly"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>

<script>
import detailsGrouping from '@/components/employees/employeeDetails/details-grouping.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    tab: {
      type: Object,
      required: true
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  components: {
    detailsGrouping
  }
};
</script>
