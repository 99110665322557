<template>
  <div class="employees">
    <hp-card class="hero-bar">
      <v-row class="align-center">
        <v-col cols="9" class="d-flex align-center">
          <strong class="font-lg font-muted mr-4">Filter</strong>

          <localization-autocomplete
            v-model="selectedPosition"
            :options="positionOptions"
            :label="$t('employeeDetails.position')"
            :placeholder="$t('employeeDetails.position')"
            :readonly="false"
            class="mr-2"
            flat
            solo
            hide-details
            clearable
          />

          <localization-autocomplete
            v-model="selectedLocation"
            :options="locationOptions"
            :label="$t('employeeDetails.location')"
            :placeholder="$t('employeeDetails.location')"
            :readonly="false"
            class="mr-2"
            flat
            solo
            hide-details
            clearable
          />

          <v-text-field
            :label="$t('searchKeyword')"
            :placeholder="$t('searchKeyword')"
            v-model="keyword"
            class="flex-grow-0"
            min-width="300"
            solo
            flat
            hide-details
          >
            <template #append>
              <v-icon right> mdi-magnify </v-icon>
            </template>
          </v-text-field>
          <!--<hp-button
            v-if="!appLoading"
            @click="createUserReport"
            prepend-icon="mdi-file-excel"
          />-->
        </v-col>
        <v-col cols="3">
          <v-select
            :items="sortingDropdown"
            label="Sortieren"
            v-model="sortColumn"
            item-text="text"
            item-value="key"
            flat
            solo
            hide-details
          >
            <template v-slot:append-outer>
              <hp-button plain icon @click="changeSortDirection">
                <v-icon v-if="sortDirection === 'asc'"> mdi-arrow-up </v-icon>
                <v-icon v-if="sortDirection === 'desc'">
                  mdi-arrow-down
                </v-icon>
              </hp-button>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row class="mt-0" v-if="!appLoading">
        <v-col cols="2">
          <strong class="mr-4">
            {{ employeesCount }} {{ $t('employees') }}
          </strong>
        </v-col>
      </v-row>
    </hp-card>

    <v-container v-if="appLoading || !employees || !widgetLayout" class="pa-0">
      <v-skeleton-loader
        type="article, list-item, actions"
        class="mt-4"
        loading
      />
    </v-container>

    <employees-overview
      v-else
      v-model="pagedEmployees"
      :layout="widgetLayout"
    />
    <div v-show="employeesCount > pageSize" class="text-center">
      <v-pagination
        v-model="pageIndex"
        :length="Math.ceil(employeesCount / pageSize)"
        circle
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import employeesOverview from '@/components/employees/employeesOverview/employees-overview.vue';
import localizationAutocomplete from '@/components/misc/localization-autocomplete.vue';

export default {
  name: 'Employees',

  components: {
    employeesOverview,
    localizationAutocomplete
  },

  data: () => ({
    keyword: '',
    pageIndex: 1,
    pageSize: 50,
    sortColumn: 'lastName',
    sortDirection: 'asc',
    excludedFilterProps: ['languageKey', 'picture', '__ob__'],
    selectedPosition: [],
    selectedLocation: []
  }),

  computed: {
    ...mapGetters({
      options: 'employees/getEmployeeDataOptions',
      employees: 'employees/getEmployees',
      widgetLayout: 'layouts/getEmployeeWidgetLayout',
      currentLanguageKey: 'languageKey',
      appLoading: 'loading'
    }),

    employeesCount() {
      return this.filteredEmployees?.length ?? 0;
    },

    filteredEmployees() {
      let filteredData = this.employees;
      if (this.keyword) {
        const kw = this.keyword.toLowerCase();
        filteredData = filteredData.filter((x) =>
          this.matchesKeyword(x, kw.toLowerCase())
        );
      }

      if (this.selectedPosition?.length > 0) {
        filteredData = filteredData.filter(
          (x) => x.position?.id === this.selectedPosition[0].id
        );
      }

      if (this.selectedLocation?.length > 0) {
        filteredData = filteredData.filter(
          (x) => x.location?.id === this.selectedLocation[0].id
        );
      }

      if (this.sortColumn) {
        const col = this.sortColumn;
        const dir = this.sortDirection === 'asc' ? 1 : -1;
        filteredData = filteredData.sort((x, y) => {
          if (typeof x[col] === 'string') {
            return x[col].localeCompare(y[col]) * dir;
          }

          return (x[col] - y[col]) * dir;
        });
      }

      return filteredData;
    },

    pagedEmployees() {
      return this.filteredEmployees.slice(
        (this.pageIndex - 1) * this.pageSize,
        this.pageIndex * this.pageSize
      );
    },

    sortingDropdown() {
      return [
        { key: 'firstName', text: this.$t('employeeDetails.firstName') },
        { key: 'lastName', text: this.$t('employeeDetails.lastName') }
      ];
    },

    locationOptions() {
      return this.options?.locations?.map((x) => x.translation) ?? [];
    },

    positionOptions() {
      return this.options?.positions?.map((x) => x.translation) ?? [];
    }
  },

  watch: {
    filteredEmployees: {
      handler() {
        this.setFilteredEmployees(this.filteredEmployees);
      },
      deep: true
    }
  },

  mounted() {
    this.setFilteredEmployees(this.filteredEmployees);
  },

  methods: {
    ...mapActions({
      setFilteredEmployees: 'employees/setFilteredEmployees'
    }),

    changeSortDirection: function () {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
      } else if (this.sortDirection === 'desc') {
        this.sortDirection = 'asc';
      }
    },

    matchesKeyword(employee, keyword) {
      if (!employee) {
        return false;
      }

      const title = employee.title?.toLowerCase() ?? '';
      const firstName = employee.firstName.toLowerCase();
      const lastName = employee.lastName.toLowerCase();
      return (
        `${title} ${firstName} ${lastName}`.trim().includes(keyword) ||
        `${title} ${lastName} ${firstName}`.trim().includes(keyword) ||
        this.searchObjectForKeyword(employee, keyword)
      );
    },

    searchFieldForKeyword(field, keyword) {
      if (!field) {
        return false;
      }

      if (typeof field === 'string' && !this.isGuid(field)) {
        return field.toLowerCase().includes(keyword);
      }

      if (typeof field === 'object') {
        if (Array.isArray(field)) {
          return this.searchArrayForKeyword(field, keyword);
        }

        return this.searchObjectForKeyword(field, keyword);
      }

      return false;
    },

    searchObjectForKeyword(object, keyword) {
      const props = Object.getOwnPropertyNames(object)
        .filter((x) => !this.excludedFilterProps.includes(x))
        .filter((x) => object[x]);

      return this.searchArrayForKeyword(
        props.map((x) => object[x]),
        keyword
      );
    },

    searchArrayForKeyword(array, keyword) {
      if (!array?.length) {
        return false;
      }

      if (!array.every((x) => x)) {
        return this.searchArrayForKeyword(
          array.filter((x) => x),
          keyword
        );
      }

      // if the array is a translation array, fetch translation for current language. If not or current language does not exist, check all.
      if (array.every((x) => x.languageKey)) {
        const translation = array.find(
          (x) => x.languageKey === this.currentLanguageKey
        );
        if (translation) {
          return this.searchObjectForKeyword(translation, keyword);
        }
      }

      // if array is a skill array, fetch translations from options and search these
      if (array.every((x) => x.skillId)) {
        const languageTranslations = array.map(
          (x) =>
            this.options.languages.find((l) => l.id === x.skillId)?.translation
        );

        const skillTranslations = array.map(
          (x) =>
            this.options.skills.find((s) => s.id === x.skillId)?.translation
        );

        return (
          languageTranslations.some((x) =>
            this.searchArrayForKeyword(x, keyword)
          ) ||
          skillTranslations.some((x) => this.searchArrayForKeyword(x, keyword))
        );
      }

      return array.some((x) => this.searchFieldForKeyword(x, keyword));
    },

    isGuid(string) {
      return string
        .toLowerCase()
        .match('[0-9a-f]{8}(-[0-9a-f]{4}){3}-[0-9a-f]{12}');
    }
  }
};
</script>

<style scoped>
.no-box-shadow-bar .v-toolbar__content {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.theme--light.v-card {
  background-color: #ffffff;
}
</style>
