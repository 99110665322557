<template>
  <div class="employee-details__grouping">
    <h5>{{ localize(grouping.translation) }}</h5>

    <div v-if="grouping.fields">
      <template v-for="field in grouping.fields">
        <details-field
          v-model="value"
          :field="field"
          :key="field.id"
          :readonly="readonly"
          showName
        />
      </template>
    </div>
  </div>
</template>

<script>
import detailsField from '@/components/employees/employeeDetails/details-field.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    grouping: {
      type: Object,
      required: true
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  components: {
    detailsField
  }
};
</script>
<style scoped>
.v-card,
.v-card__text {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.v-card .row {
  flex-grow: 0;
}

.v-card .row + .row {
  margin-top: -12px;
}
</style>
