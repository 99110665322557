import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('hp-card',{staticClass:"employee-details"},[_c(VTabs,{attrs:{"align-with-title":"","grow":""},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c(VTabsSlider,{attrs:{"color":"var(--v-primary-base)"}}),(_vm.layout.tabs)?_vm._l((_vm.layout.tabs),function(tab){return _c(VTab,{key:tab.id},[_vm._v(" "+_vm._s(_vm.localize(tab.translation))+" ")])}):_vm._e(),_c(VTab,[_vm._v(" "+_vm._s(_vm.$t('skills.name'))+" ")])],2),(_vm.layout.tabs)?_c(VTabsItems,{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_vm._l((_vm.layout.tabs),function(tab){return [_c('employee-details-tab',{key:tab.id,attrs:{"tab":tab,"readonly":_vm.readonly},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})]}),_c('skills-tab',{attrs:{"readonly":_vm.readonly},model:{value:(_vm.value.skills),callback:function ($$v) {_vm.$set(_vm.value, "skills", $$v)},expression:"value.skills"}})],2):_vm._e()],1),_c('br'),(!_vm.readonly)?_c('hp-card',{staticClass:"flex-grow-1",staticStyle:{"height":"100%"}},[_c(VRow,[_c(VCol,{staticClass:"text-right",attrs:{"cols":"12"}},[_c('delete-modal',{on:{"delete":_vm.deleteEmployee}}),_c('hp-button',{attrs:{"primary":""},on:{"click":_vm.save}},[_c(VIcon,[_vm._v("mdi-content-save-outline")]),_vm._v(_vm._s(_vm.$t('save'))+" ")],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }