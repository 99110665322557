<template>
  <v-sheet
    class="image-upload__dropzone mt-4"
    @click="openFileDialog"
    @drop.prevent="previewFileDrag"
    @dragenter.prevent="showUploadHint = true"
    @dragover.prevent="showUploadHint = true"
    @dragleave.prevent="showUploadHint = false"
    @mouseenter.prevent="showUploadHint = true"
    @mouseover.prevent="showUploadHint = true"
    @mouseleave.prevent="showUploadHint = false"
  >
    <api-image
      v-show="!showUploadHint"
      :bucket="defaultBucket"
      :file-name="defaultFileName"
      :src="imagePreview"
    />

    <div class="image-upload__dropzone__hint" v-show="showUploadHint">
      <div>{{ $t('files.imageUploadHint') }}</div>
    </div>

    <input
      ref="fileInput"
      type="file"
      accept="image/*"
      style="display: none"
      @change="previewFile"
    />
  </v-sheet>
</template>

<script>
import apiImage from '@/components/misc/api-image.vue';
import { mapActions } from 'vuex';

export default {
  props: {
    defaultBucket: {
      type: String,
      required: false,
      default: 'public'
    },

    defaultFileName: {
      type: String,
      required: false,
      default: 'default_avatar.jpg'
    },

    employeeId: {
      type: String,
      required: false,
      default: null
    }
  },

  data: () => ({
    imagePreview: null,
    file: null,
    showUploadHint: false
  }),

  methods: {
    ...mapActions({
      saveFile: 'files/saveFile'
    }),

    openFileDialog() {
      this.$refs.fileInput.click();
    },

    previewFile(event) {
      const files = [...event.target.files].filter(
        (x) => x.size > 0 && x.type.startsWith('image/')
      );

      if (files.length > 0) {
        const file = files[0];
        this.imagePreview = URL.createObjectURL(file);
        const reader = new FileReader();
        reader.onerror = (err) => {
          console.log(err);
          this.$emit('change', null);
        };

        reader.onload = async () => {
          const parts = reader.result.split(';');
          const contentType = parts[0].replace('data:', '');
          const fileContent = parts[1].replace('base64,', '');

          const data = {
            fileName: file.name,
            fileContent: fileContent,
            contentType: contentType,
            fileType: 4
          };

          const displayFile = await this.saveFile({
            bucket: this.defaultBucket,
            data: data,
            employeeId: this.employeeId
          });

          this.$emit('change', displayFile);
        };

        reader.readAsDataURL(file);
      }
    },

    previewFileDrag(event) {
      this.itemDragged = false;
      const previewEvent = {
        target: {
          files: event.dataTransfer.files
        }
      };

      this.previewFile(previewEvent);
    }
  },

  components: {
    apiImage
  }
};
</script>

<style lang="scss" scoped>
.image-upload__dropzone {
  position: relative;
}

.image-upload__dropzone:hover {
  border: 1px dashed var(--font-muted);
  cursor: pointer;
}

.image-upload__dropzone__hint {
  padding: 1em 1em 1em 1em;
  align-self: center;
  background-color: rgba(255, 255, 255, 0.75);
}
</style>
