<template>
  <hp-label
    :color="tagClass"
    :title="skillLevelName"
    data-toggle="tooltip"
    class="mr-1 mb-1"
  >
    <slot />
  </hp-label>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },

  computed: {
    skillLevelName() {
      switch (this.value.skillType) {
        case 1:
          return this.$t(`skills.languageLevels.${this.value.skillLevel}`);
        case 2:
        case 3:
          return this.$t(`skills.competencyLevels.${this.value.skillLevel}`);
        default:
          return '';
      }
    },

    warningMinimum() {
      switch (this.value.skillType) {
        case 1:
          return 3;
        case 2:
        case 3:
          return 2;
        default:
          return 0;
      }
    },

    successMinimum() {
      switch (this.value.skillType) {
        case 1:
          return 5;
        case 2:
        case 3:
          return 4;
        default:
          return 0;
      }
    },

    tagClass() {
      let tagClass = 'red';

      if (!this.skillLevelName) {
        tagClass = '';
      } else if (this.value.skillLevel >= this.successMinimum) {
        tagClass = 'green';
      } else if (this.value.skillLevel >= this.warningMinimum) {
        tagClass = 'orange';
      }

      return tagClass;
    }
  }
};
</script>

<style scoped>
a:hover {
  cursor: pointer;
}
.tooltip-inner {
  background-color: #222222;
  font-size: 12px;
  padding: 4px 10px;
}
.label {
  margin-right: 5px;
  color: white;
  padding: 0 6px;
  display: inline-block;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25em;
  margin-bottom: 5px;
}
</style>
