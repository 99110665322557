<template>
  <v-card color="var(--element-background)" flat width="100%">
    <v-spacer></v-spacer>

    <v-card-title>
      {{ employee.title }} {{ employee.lastName }},
      {{ employee.firstName }}
    </v-card-title>

    <div class="d-flex flex-no-wrap">
      <template v-if="imageFileName">
        <user-image v-if="employee" v-model="employee.picture" />
      </template>

      <div class="v-list-width" style="width: 100%">
        <v-list dense v-if="value.fields">
          <v-list-item v-for="(field, index) in value.fields" :key="field.id">
            <v-list-item-icon>
              <v-icon>{{ field.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="overflow: hidden">
                <details-field
                  v-model="value.fields[index]"
                  :employee="employee"
                />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </v-card>
</template>

<script>
import detailsField from '@/components/layouts/employeeDetails/details-field.vue';
import userImage from '@/components/misc/user-image.vue';
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    employee: {
      type: Object,
      required: true
    }
  },

  components: {
    detailsField,
    userImage
  }
};
</script>
<style scoped>
.v-card__title {
  padding-bottom: 0;
}
</style>
