<template>
  <div>
    <template v-if="readonly">{{ localize(value) }} </template>
    <v-autocomplete
      v-else
      v-model="choiceInSelectedLanguage"
      :items="optionsInSelectedLanguage"
      return-object
      v-bind="$attrs"
      item-value="id"
      item-text="translation"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    // the value property is an array of translations. Each translations
    // must consist of (at least) an id and translation property.
    // value = [ { id = 'xxxx-xxxx-xxxx-xxxx', translation = 'my translation' } ]
    value: {
      type: Array,
      required: false,
      default: () => []
    },

    // each item in the items array is another array in the same form as the value property
    options: {
      type: Array,
      required: false,
      default: () => []
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  mounted() {
    this.updateChoice();
  },

  watch: {
    value: {
      handler() {
        this.updateChoice();
      },
      deep: true
    },

    choiceInSelectedLanguage() {
      const choiceItem = this.options.find(
        (x) => x[0].id === this.choiceInSelectedLanguage?.id
      );
      this.$emit('input', choiceItem);
    }
  },

  computed: {
    ...mapGetters({
      currentLanguageKey: 'languageKey'
    }),

    optionsInSelectedLanguage() {
      // map the items array to another array which only
      // includes the items in the current language. This step is neccessary
      // because the v-autocomplete cannot handle an array with multiple
      // translations as a v-model very well.
      return this.options
        .map(
          (x) =>
            x.find((item) => item.languageKey === this.currentLanguageKey) ??
            x[0]
        )
        .sort((x, y) => x.translation.localeCompare(y.translation));
    }
  },

  methods: {
    updateChoice() {
      if (!this.value || this.value.length === 0) {
        this.choiceInSelectedLanguage = '';
        return;
      }
      this.choiceInSelectedLanguage =
        this.value.find((x) => x.languageKey === this.currentLanguageKey) ??
        this.value[0];
    }
  },

  data() {
    return {
      choiceInSelectedLanguage: ''
    };
  }
};
</script>
