import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"cols":"12","sm":_vm.currentSize.sm,"md":_vm.currentSize.md}},[_c('hp-widget',{attrs:{"icon":"mdi-list-status"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dashboard.widgets.todos.title'))+" ("+_vm._s(_vm.visibleItems.length)+") ")]},proxy:true},{key:"actions",fn:function(){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('hp-button',_vm._g({attrs:{"small":"","plain":"","icon":""},on:{"click":function($event){return _vm.resize({ type: 'todos' })}}},on),[_c(VIcon,[_vm._v("mdi-arrow-expand-horizontal")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.resize')))])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('hp-button',_vm._g({staticClass:"drag-widget",attrs:{"small":"","plain":"","icon":""}},on),[_c(VIcon,[_vm._v("mdi-arrow-expand-all")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.move')))])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('hp-button',_vm._g({attrs:{"small":"","plain":"","icon":""},on:{"click":function($event){return _vm.close({ type: 'todos' })}}},on),[_c(VIcon,[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.remove')))])])]},proxy:true}])},[_c(VList,[(_vm.loading)?_c(VListItemGroup,[_c(VListItem,[_c(VListItemContent,[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"secondary"}})],1)],1)],1):(_vm.visibleItems && _vm.visibleItems.length > 0)?_c(VListItemGroup,[_vm._l((_vm.visibleItems),function(item,index){return [_c(VListItem,{key:((item.onboardingTaskId) + " " + (item.onboardingUserId)),on:{"click":function($event){return _vm.goToTask(item)}}},[[_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"font-weight":"bold"},domProps:{"innerHTML":_vm._s(_vm.getStepName(item))}}),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.getUserName(item.onboardingUserId))+" ")])],1),_c(VListItemAction,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.setTaskVisible($event, item, false)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-eye-off ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.widgets.todos.hide')))])])],1)]],2),_c(VDivider,{key:index})]})],2):_c(VListItemGroup,[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t('dashboard.widgets.todos.noTasks'))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }