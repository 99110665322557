import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"segmented mt-4",attrs:{"flat":""}},[_c(VCardTitle,[_c(VCol,{attrs:{"cols":"12","md":"11"}},[_c('localization-text-field',{attrs:{"readonly":_vm.readonly,"dense":""},model:{value:(_vm.value.translation),callback:function ($$v) {_vm.$set(_vm.value, "translation", $$v)},expression:"value.translation"}})],1),(!_vm.readonly)?_c(VCol,{attrs:{"cols":"12","md":"1"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.deleteGrouping}},[_c(VIcon,[_vm._v("mdi-delete")])],1)],1):_vm._e()],1),(_vm.value.fields)?_c(VCardText,[_vm._l((_vm.value.fields),function(field,index){return [_c('details-field',{key:field.id,attrs:{"employee":_vm.employee,"readonly":_vm.readonly,"showName":""},on:{"delete":_vm.deleteField},model:{value:(_vm.value.fields[index]),callback:function ($$v) {_vm.$set(_vm.value.fields, index, $$v)},expression:"value.fields[index]"}})]}),(!_vm.readonly)?_c(VContainer,{staticClass:"py-3 px-0"},[_c(VRow,[_c(VCol,{staticClass:"text-right",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"white--text",attrs:{"color":"primary"},on:{"click":_vm.createField}},[_vm._v(" "+_vm._s(_vm.$t('layouts.addField'))+" ")])],1)],1)],1):_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }