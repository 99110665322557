<template>
  <numeric-field
    v-model="number"
    v-bind="$attrs"
    :readonly="readonly"
    :format="format"
    append-icon="mdi-currency-eur"
  />
</template>

<script>
import numericField from './numeric-field.vue';

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      number: ''
    };
  },

  computed: {
    format() {
      return {
        maximumFractionDigits: 2
      };
    }
  },

  watch: {
    value() {
      this.number = this.value;
    },

    number() {
      if (this.number) {
        this.$emit('input', this.number);
      }
    }
  },

  mounted() {
    this.number = this.value;
  },

  components: {
    numericField
  }
};
</script>
