<template>
  <div>
    <template v-if="readonly">{{ formattedDatetime }}</template>
    <v-menu
      v-else
      v-model="showPicker"
      :close-on-content-click="false"
      :nudge-right="0"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="formattedDatetime"
          :label="label"
          v-bind="$attrs"
          v-on="on"
          color="secondary"
          header-color="primary"
          :prepend-inner-icon="hasIcon ? 'mdi-calendar' : ''"
          :required="required"
          :rules="rules"
          clearable
          readonly
          single-line
        />
      </template>
      <v-tabs v-model="activeTab" center-active>
        <v-tab>
          <v-icon>mdi-calendar</v-icon>
        </v-tab>

        <v-tab>
          <v-icon>mdi-clock</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <v-date-picker
            v-model="date"
            :first-day-of-week="1"
            :min="minimumDate"
            locale="de-DE"
            @input="dateSelected"
          ></v-date-picker>
        </v-tab-item>
        <v-tab-item>
          <v-time-picker
            v-model="time"
            ref="timePicker"
            format="24hr"
            :readonly="readonly"
            @click:minute="timeSelected"
          ></v-time-picker>
        </v-tab-item>
      </v-tabs-items>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },

    label: {
      type: String,
      required: false,
      default: ''
    },

    required: {
      type: Boolean,
      required: false,
      default: false
    },

    rules: {
      type: Array,
      required: false,
      default: () => []
    },

    hasIcon: {
      type: Boolean,
      required: false,
      default: true
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    },

    disablePastDates: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    date: '',
    time: '',
    formattedDatetime: '',
    showPicker: false,
    activeTab: 0
  }),

  watch: {
    date() {
      this.$emit('input', this.datetime);
    },

    time() {
      this.$emit('input', this.datetime);
    },

    value() {
      this.setDatetimeToValue();
    },

    languageKey() {
      this.formatDatetime();
    }
  },

  mounted() {
    this.setDatetimeToValue();
  },

  computed: {
    ...mapGetters({
      languageKey: 'languageKey',
      datetimeFormat: 'datetimeFormat'
    }),

    datetime() {
      if (!this.date || !this.time) {
        return '';
      }

      return `${this.date}T${this.time}`;
    },

    minimumDate() {
      return this.disablePastDates ? new Date().toISOString() : undefined;
    }
  },

  methods: {
    setDatetimeToValue() {
      if (this.value) {
        const [date, time] = this.value.split('T');
        const [hour, minute] = time.split(':');
        this.date = date;
        this.time = `${hour}:${minute}`;
      }

      this.formatDatetime();
    },

    dateSelected() {
      this.activeTab = 1;
    },

    timeSelected() {
      this.showPicker = false;
      this.activeTab = 0;
    },

    formatDatetime() {
      if (!this.datetime) {
        this.formattedDatetime = '';
        return;
      }

      const date = new Date(this.datetime);
      this.formattedDatetime = date.toLocaleString(
        this.languageKey,
        this.datetimeFormat
      );
    }
  }
};
</script>
