<template>
  <div>
    <v-row>
      <v-col cols="12" style="margin-top: 8px">
        <hp-card class="flex-grow-1" style="height: 100%">
          <v-row>
            <v-col cols="4" class="text-left">
              <hp-button
                v-if="!loading && previousEmployee"
                @click="routeToEmployee(previousEmployee)"
              >
                <v-icon>mdi-arrow-left</v-icon>
                {{ $t('employee.previousEmployee') }}
              </hp-button>
            </v-col>
            <v-col cols="4" class="text-center">
              <hp-button v-if="!loading && employee" @click="routeToOnboarding">
                <v-icon>mdi-clipboard-check-multiple</v-icon>
                {{ $t('onboarding.name') }}
              </hp-button>
            </v-col>
            <v-col cols="4" class="text-right">
              <hp-button
                v-if="!loading && nextEmployee"
                @click="routeToEmployee(nextEmployee)"
              >
                {{ $t('employee.nextEmployee') }}
                <v-icon>mdi-arrow-right</v-icon>
              </hp-button>
            </v-col>
          </v-row>
        </hp-card>
      </v-col>
      <v-col cols="12" md="8" lg="9">
        <v-skeleton-loader
          v-if="loading || !employee || !summaryLayout"
          type="article, list-item, actions"
          class="mt-4"
          loading
        />

        <employee-summary
          v-else
          v-model="employee"
          :isAdmin="isAdmin"
          :layout="summaryLayout"
          :readonly="readonly"
          @delete="del"
        />
      </v-col>

      <v-col cols="12" md="4" lg="3">
        <v-skeleton-loader
          v-if="loading || !employee || !summaryLayout"
          type="article, list-item, actions"
          class="mt-4"
          loading
        />
        <hp-card v-else class="flex-grow-1" style="height: 100%">
          <h3>{{ $t('employeeDetails.languages') }}</h3>

          <template v-if="employee && !loading">
            <details-tag
              v-for="(skill, index) in languages"
              :key="skill.skillId"
              v-model="languages[index]"
            >
              {{ getSkillTranslation(skill.skillId, skill.skillType) }}
            </details-tag>
          </template>

          <h3>{{ $t('employeeDetails.skills') }}</h3>

          <template v-if="employee && !loading">
            <details-tag
              v-for="(skill, index) in competencies"
              :key="skill.skillId"
              v-model="competencies[index]"
            >
              {{ getSkillTranslation(skill.skillId, skill.skillType) }}
            </details-tag>
          </template>

          <h3>Tags</h3>

          <template v-if="employee && !loading">
            <details-tag
              v-for="(tag, index) in tags"
              :key="index"
              v-model="tags[index]"
            >
              {{ tag }}
            </details-tag>
          </template>
        </hp-card>
      </v-col>
    </v-row>

    <br />
    <v-skeleton-loader
      v-if="loading || !employee || !detailsLayout"
      type="article, list-item, actions"
      class="mt-4"
      loading
    />

    <employee-details
      v-else
      v-model="employee"
      :layout="detailsLayout"
      :readonly="readonly"
      :isAdmin="isAdmin"
      @save="save"
      @delete="del"
      ref="detailsComponent"
    />

    <snackbar
      v-model="snackbar"
      color="success"
      icon="mdi-check-circle"
      :text="$t('toast.dataSaved')"
    />

    <snackbar
      v-model="errorMessage"
      color="error"
      icon="mdi-exclamation-triangle"
      :text="$t('toast.errorOcurred')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import employeeSummary from '@/components/employees/employeeDetails/summary.vue';
import employeeDetails from '@/components/employees/employeeDetails/details.vue';
import detailsTag from '@/components/employees/employeeDetails/details-tag.vue';
import snackbar from '@/components/misc/snackbar.vue';

export default {
  name: 'Employees',

  data() {
    return {
      dataLoading: true,
      snackbar: false,
      errorMessage: false
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      employee: 'employees/getCurrentEmployee',
      detailsLayout: 'layouts/getEmployeePageLayout',
      summaryLayout: 'layouts/getEmployeeSummaryLayout',
      options: 'employees/getEmployeeDataOptions',
      filteredEmployees: 'employees/getFilteredEmployees',
      appLoading: 'loading'
    }),

    readonly() {
      return !this.isAdmin && !this.currentUser?.id === this.employee.id;
    },

    isAdmin() {
      return this.currentUser?.isAdmin ?? false;
    },

    loading() {
      return this.dataLoading || this.appLoading;
    },

    languages() {
      return this.skills.filter((x) => x.skillType === 1);
    },

    competencies() {
      return this.skills.filter((x) => x.skillType === 2);
    },

    skills() {
      const skills = [...this.employee?.skills] ?? [];
      return skills.sort((x, y) => y.skillLevel - x.skillLevel);
    },

    tags() {
      return this.employee?.tags ?? [];
    },

    currentEmployeeIndex() {
      return (
        this.filteredEmployees?.findIndex((x) => x.id === this.employee?.id) ??
        -1
      );
    },

    previousEmployee() {
      if (this.currentEmployeeIndex <= 0) {
        return null;
      }

      return this.filteredEmployees[this.currentEmployeeIndex - 1];
    },

    nextEmployee() {
      if (
        this.currentEmployeeIndex < 0 ||
        this.currentEmployeeIndex >= this.filteredEmployees.length - 1
      ) {
        return null;
      }

      return this.filteredEmployees[this.currentEmployeeIndex + 1];
    }
  },

  async mounted() {
    await this.load();
  },

  watch: {
    '$route.params': async function () {
      this.dataLoading = true;
      await this.load();
    }
  },

  methods: {
    ...mapActions({
      fetchEmployee: 'employees/fetchEmployee',
      saveEmployee: 'employees/saveEmployee',
      deleteEmployee: 'employees/deleteEmployee'
    }),

    async load() {
      const userId = this.$route.params.id;
      if (!this.employee?.id || this.employee.id !== userId) {
        await this.fetchEmployee(userId);
      }

      this.dataLoading = false;
      if (this.$route.query.tab) {
        const tab = parseInt(this.$route.query.tab);
        if (!isNaN(tab)) {
          this.$nextTick(() => {
            this.$refs.detailsComponent.setTab(tab);
          });
        }
      }
    },

    async save() {
      const success = await this.saveEmployee(this.employee);
      this.snackbar = success;
      this.errorMessage = !success;
    },

    async del() {
      await this.deleteEmployee(this.employee.id);
      this.$router.push({
        name: 'EmployeesOverview'
      });
    },

    getSkillTranslation(skillId, skillType) {
      let options = [];
      switch (skillType) {
        case 1:
          options = this.options.languages;
          break;
        case 2:
        default:
          options = this.options.skills;
          break;
      }

      var translation = options.find((x) => x.id === skillId)?.translation;
      if (translation) {
        return this.localize(translation);
      }

      return '';
    },

    routeToEmployee(employee) {
      if (!employee?.id) {
        return;
      }

      const target = {
        name: 'EmployeeDetails',
        params: { id: employee.id }
      };

      if (this.$refs.detailsComponent.currentTab) {
        target.query = { tab: this.$refs.detailsComponent.currentTab };
      }

      this.$router.push(target);
    },

    routeToOnboarding() {
      if (!this.employee?.id) {
        return;
      }

      this.$router.push({
        name: 'OnboardingDetails',
        params: { id: this.employee.id }
      });
    }
  },

  components: {
    employeeSummary,
    employeeDetails,
    detailsTag,
    snackbar
  }
};
</script>
<style scoped>
.v-btn .v-icon {
  margin-left: 8px;
  margin-right: 8px;
}
</style>
