<template>
  <v-tab-item>
    <v-container>
      <v-row v-if="value.groupings">
        <v-col v-for="(grouping, index) in value.groupings" :key="grouping.id">
          <details-grouping
            v-model="value.groupings[index]"
            :employee="employee"
            :readonly="readonly"
            @delete="deleteGrouping"
          />
        </v-col>
      </v-row>
      <v-row v-if="!readonly">
        <v-col cols="12" class="text-right">
          <v-btn color="primary" class="white--text" @click="createGrouping">
            {{ $t('layouts.addGrouping') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-tab-item>
</template>

<script>
import detailsGrouping from '@/components/layouts/employeeDetails/details-grouping.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    employee: {
      type: Object,
      required: true
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  methods: {
    deleteGrouping(groupingId, groupingIndex) {
      this.value.groupings = this.value.groupings.filter(
        (x) => x.id !== groupingId
      );
      this.value.groupings.forEach((x) => x.index > groupingIndex && x.index--);
    },

    createGrouping() {
      this.value.groupings.push({
        index: this.value.groupings.length,
        translation: [
          { languageKey: 'de-DE', translation: 'Neue Gruppierung' },
          { languageKey: 'en-US', translation: 'New grouping' }
        ],
        fields: []
      });
    }
  },

  components: {
    detailsGrouping
  }
};
</script>
