<template>
  <hp-card flat class="employee-details__summary">
    <div class="d-flex">
      <div>
        <template v-if="imageFileName">
          <api-image
            v-if="readonly"
            :bucket="imageBucket"
            :file-name="imageFileName"
          />
          <image-upload
            v-else
            :defaultBucket="imageBucket"
            :defaultFileName="imageFileName"
            @change="setImage"
          />
        </template>
      </div>

      <div class="flex-grow-1 ml-8">
        <h2 class="mt-4">
          {{ value.title }} {{ value.lastName }},
          {{ value.firstName }}
        </h2>
        <div style="display: flex">
          <div
            v-for="column of columns"
            :key="column"
            class="v-list-width"
            style="flex: 1"
          >
            <v-list dense>
              <v-list-item
                v-for="field in getFieldsByColumn(column)"
                :key="field.id"
                class="pl-0"
              >
                <v-list-item-icon class="mr-0">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary" small v-on="on">{{
                        field.icon
                      }}</v-icon>
                    </template>
                    <span>{{ localize(field.translation) }} </span>
                  </v-tooltip>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="white-space: normal">
                    <details-field v-model="value" :field="field" />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </div>
      </div>
    </div>
  </hp-card>
</template>

<script>
import apiImage from '@/components/misc/api-image.vue';
import imageUpload from '@/components/misc/image-upload.vue';

import detailsField from '@/components/employees/employeeDetails/details-field.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    layout: {
      type: Object,
      required: true
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    },

    isAdmin: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    fields() {
      return this.layout.fields ?? [];
    },

    columns() {
      const indices = this.fields.map((x) => x.columnIndex);
      return [...new Set(indices)].sort();
    },

    languages() {
      return this.value.skills?.filter((x) => x.skillType === 1);
    },

    competencies() {
      return this.value.skills?.filter((x) => x.skillType === 2);
    },

    pictureObj() {
      if (!this.value?.picture) {
        return null;
      }

      const filePath = this.value.picture.filePath;
      if (
        filePath &&
        filePath.indexOf('{') >= 0 &&
        filePath.indexOf('}') >= 0
      ) {
        return JSON.parse(filePath);
      }

      return null;
    },

    imageFileName() {
      return this.pictureObj?.FileName ?? 'default_avatar.jpg';
    },

    imageBucket() {
      return this.pictureObj?.Bucket ?? 'public';
    }
  },

  methods: {
    getFieldsByColumn(column) {
      return this.fields.filter((x) => x.columnIndex === column);
    },

    async setImage(selectedImage) {
      this.value.picture = selectedImage;
    },

    deleteEmployee() {
      this.$emit('delete');
    }
  },

  components: {
    apiImage,
    imageUpload,
    detailsField
  }
};
</script>
<style scoped>
.v-card__title {
  padding-bottom: 0;
}

.v-card .col {
  padding: 0;
}

.v-image {
  max-height: 200px;
  max-width: 200px;
}

.image-upload__dropzone {
  position: static;
  display: flex;
  max-height: 200px;
  max-width: 200px;
  min-height: 200px;
  min-width: 200px;
}
</style>
