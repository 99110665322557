<template>
  <div>
    <v-card class="segmented mt-4" flat>
      <v-tabs
        v-model="currentTab"
        align-with-title
        background-color="var(--element-background)"
        grow
      >
        <v-tabs-slider color="var(--navigation-drawer-textcolor)" />

        <template v-if="value.tabs">
          <v-tab v-for="tab in value.tabs" :key="tab.id">
            <v-col cols="12" md="11">
              <localization-text-field
                v-model="tab.translation"
                :readonly="readonly"
              />
            </v-col>
            <v-col cols="12" md="1">
              <v-btn v-if="!readonly" icon @click.stop="deleteTab(tab)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-tab>
        </template>
        <v-row v-if="!readonly" class="align-center">
          <v-col cols="12" class="text-right">
            <v-btn
              color="primary"
              class="white--text"
              @click.stop="createTab"
              enabled
            >
              {{ $t('layouts.addTab') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-tabs>
      <v-tabs-items v-model="currentTab" v-if="value.tabs">
        <template v-for="(tab, index) in value.tabs">
          <employee-details-tab
            v-model="value.tabs[index]"
            :employee="employee"
            :key="tab.id"
            :readonly="readonly"
          />
        </template>
      </v-tabs-items>
    </v-card>
    <v-container class="py-3 px-0" v-if="!readonly">
      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn large color="primary" class="white--text" @click="save">
            <v-icon>mdi-content-save-outline</v-icon>{{ $t('save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import localizationTextField from '@/components/misc/localization-text-field.vue';
import employeeDetailsTab from '@/components/layouts/employeeDetails/details-tab.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    employee: {
      type: Object,
      required: true
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    },

    isAdmin: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      currentTab: null
    };
  },

  methods: {
    save() {
      this.$emit('save');
    },

    deleteTab(tab) {
      this.value.tabs = this.value.tabs.filter((x) => x.id !== tab.id);
      this.value.tabs.forEach((x) => x.index > tab.index && x.index--);
      this.currentTab = 0;
    },

    createTab() {
      this.value.tabs.push({
        index: this.value.tabs.length,
        translation: [
          { languageKey: 'de-DE', translation: 'Neuer Tab' },
          { languageKey: 'en-US', translation: 'New tab' }
        ],
        groupings: []
      });
    }
  },

  components: {
    employeeDetailsTab,
    localizationTextField
  }
};
</script>
