import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"cols":"12","sm":_vm.currentSize.sm,"md":_vm.currentSize.md}},[_c('hp-widget',{attrs:{"icon":"mdi-calendar"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('dashboard.widgets.calendar')))]},proxy:true},{key:"actions",fn:function(){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('hp-button',_vm._g({attrs:{"small":"","plain":"","icon":""},on:{"click":function($event){return _vm.resize({ type: 'calendar' })}}},on),[_c(VIcon,[_vm._v("mdi-arrow-expand-horizontal")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.resize')))])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('hp-button',_vm._g({staticClass:"drag-widget",attrs:{"small":"","plain":"","icon":""}},on),[_c(VIcon,[_vm._v("mdi-arrow-expand-all")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.move')))])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('hp-button',_vm._g({attrs:{"small":"","plain":"","icon":""},on:{"click":function($event){return _vm.close({ type: 'calendar' })}}},on),[_c(VIcon,[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.remove')))])])]},proxy:true}])},[_c(VAppBar,{attrs:{"flat":""}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-0",attrs:{"small":"","icon":""},on:{"click":function($event){_vm.type = 'month'}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-calendar-month")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.calendar.monthView')))])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){_vm.type = 'week'}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-calendar-week")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.calendar.weekView')))])]),_c(VSpacer),_c(VToolbarTitle,{staticClass:"text-h6 pl-0"},[_vm._v(_vm._s(_vm.dateLabel))]),_c(VSpacer),_c(VBtn,{attrs:{"small":"","icon":""},on:{"click":_vm.prev}},[_c(VIcon,{staticStyle:{"cursor":"pointer"}},[_vm._v("mdi-chevron-left")])],1),_c(VBtn,{attrs:{"small":"","icon":""},on:{"click":_vm.next}},[_c(VIcon,{staticStyle:{"cursor":"pointer"}},[_vm._v("mdi-chevron-right")])],1)],1),_c(VCalendar,{ref:"calendar",attrs:{"weekday-format":_vm.weekday,"month-format":_vm.month,"color":"secondary","events":_vm.visibleEvents,"event-color":_vm.getEventColor,"event-more-text":_vm.$tc('dashboard.calendar.moreEvents', '{0}', { count: '{0}' }),"type":_vm.type,"weekdays":[1, 2, 3, 4, 5, 6, 0]},on:{"click:event":_vm.exportDate,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"mouseenter:event":_vm.openEventPopup,"mouseleave:event":_vm.closeEventPopup,"change":_vm.updateRange},model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1),_c(VTooltip,{attrs:{"position-x":_vm.popupPositionX,"position-y":_vm.popupPositionY,"right":""},model:{value:(_vm.popupVisible),callback:function ($$v) {_vm.popupVisible=$$v},expression:"popupVisible"}},[_c('span',[_vm._v(_vm._s(_vm.popupContent))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }