import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('hp-card',{staticClass:"employee-details__summary",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex"},[_c('div',[(_vm.imageFileName)?[(_vm.readonly)?_c('api-image',{attrs:{"bucket":_vm.imageBucket,"file-name":_vm.imageFileName}}):_c('image-upload',{attrs:{"defaultBucket":_vm.imageBucket,"defaultFileName":_vm.imageFileName},on:{"change":_vm.setImage}})]:_vm._e()],2),_c('div',{staticClass:"flex-grow-1 ml-8"},[_c('h2',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.value.title)+" "+_vm._s(_vm.value.lastName)+", "+_vm._s(_vm.value.firstName)+" ")]),_c('div',{staticStyle:{"display":"flex"}},_vm._l((_vm.columns),function(column){return _c('div',{key:column,staticClass:"v-list-width",staticStyle:{"flex":"1"}},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.getFieldsByColumn(column)),function(field){return _c(VListItem,{key:field.id,staticClass:"pl-0"},[_c(VListItemIcon,{staticClass:"mr-0"},[_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"primary","small":""}},on),[_vm._v(_vm._s(field.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.localize(field.translation))+" ")])])],1),_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"white-space":"normal"}},[_c('details-field',{attrs:{"field":field},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1)],1)}),1)],1)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }