<template>
  <div>
    <v-skeleton-loader
      v-if="loading || !employee || !summaryLayout"
      type="article, list-item, actions"
      class="mt-4"
      loading
    />
    <employee-summary
      v-else
      v-model="summaryLayout"
      :employee="employee"
      readonly
      @save="saveSummary"
    />
    <br />
    <v-skeleton-loader
      v-if="loading || !employee || !detailsLayout"
      type="article, list-item, actions"
      class="mt-4"
      loading
    />
    <employee-details
      v-else
      v-model="detailsLayout"
      :employee="employee"
      :readonly="readonly"
      @save="saveDetails"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import employeeSummary from '@/components/layouts/employeeDetails/summary.vue';
import employeeDetails from '@/components/layouts/employeeDetails/details.vue';

export default {
  name: 'Layouts',

  components: {
    employeeSummary,
    employeeDetails
  },

  data() {
    return {
      tab: null,
      dataLoading: true
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      employee: 'employees/getCurrentEmployee',
      summaryLayout: 'layouts/getEmployeeSummaryLayout',
      detailsLayout: 'layouts/getEmployeePageLayout',
      appLoading: 'loading'
    }),

    readonly() {
      return !this.currentUser?.isAdmin;
    },

    loading() {
      return this.dataLoading || this.appLoading;
    }
  },

  async mounted() {
    await this.fetchData();
  },

  methods: {
    ...mapActions({
      fetchEmployee: 'employees/fetchEmployee',
      saveDetailsLayout: 'layouts/saveEmployeePageLayout',
      saveSummaryLayout: 'layouts/saveEmployeeSummaryLayout'
    }),

    async fetchData() {
      const userId = this.currentUser?.id;
      if (!userId) {
        return;
      }

      if (!this.employee?.id || this.employee.id != userId) {
        await this.fetchEmployee(userId);
      }

      this.dataLoading = false;
    },

    async saveDetails() {
      await this.saveDetailsLayout(this.detailsLayout);
    },

    async saveSummary() {
      await this.saveSummaryLayout(this.summaryLayout);
    }
  }
};
</script>
