<template>
  <div>
    <template v-if="readonly">{{ formattedDate }}</template>
    <v-menu
      v-else
      v-model="showPicker"
      :close-on-content-click="false"
      :nudge-right="0"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="formattedDate"
          :label="label"
          v-bind="$attrs"
          v-on="on"
          color="secondary"
          header-color="primary"
          :prepend-inner-icon="hasIcon ? 'mdi-calendar' : ''"
          :required="required"
          :rules="rules"
          clearable
          readonly
          single-line
        ></v-text-field>
      </template>

      <v-date-picker
        v-model="date"
        :first-day-of-week="1"
        :min="minimumDate"
        locale="de-DE"
        @input="showPicker = false"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },

    label: {
      type: String,
      required: false,
      default: ''
    },

    required: {
      type: Boolean,
      required: false,
      default: false
    },

    rules: {
      type: Array,
      required: false,
      default: () => []
    },

    hasIcon: {
      type: Boolean,
      required: false,
      default: true
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    },

    disablePastDates: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      date: '',
      formattedDate: '',
      showPicker: false
    };
  },

  watch: {
    date() {
      this.formatDate();
      this.$emit('input', this.date);
    },

    value() {
      this.setDateToValue();
    },

    languageKey() {
      this.formatDate();
    }
  },

  mounted() {
    this.setDateToValue();
  },

  computed: {
    ...mapGetters({
      languageKey: 'languageKey',
      dateFormat: 'dateFormat'
    }),

    minimumDate() {
      return this.disablePastDates ? new Date().toISOString() : undefined;
    }
  },

  methods: {
    setDateToValue() {
      var dateString = this.value?.toString();
      if (dateString) {
        if (dateString.includes('T')) {
          dateString = dateString.split('T')[0];
        }

        this.date = dateString;
      }
    },

    formatDate() {
      if (!this.date) {
        this.formattedDate = '';
        return;
      }

      const date = new Date(this.date);
      this.formattedDate = date.toLocaleDateString(
        this.languageKey,
        this.dateFormat
      );
    }
  }
};
</script>
