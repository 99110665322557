export default {
  replaceEmployeePlaceholders(translation, employee) {
    if (!employee) {
      return translation;
    }

    const placeholderMatches = this.extractPlaceholders(translation);
    for (const match of placeholderMatches) {
      const matchVal = match[1];
      let val = employee[matchVal];
      if (matchVal.startsWith('ci.')) {
        const customId = matchVal.substring(3);
        const customInfo = employee.customInformation.find(
          (x) =>
            !x.customFieldId.localeCompare(customId, undefined, {
              sensitivity: 'accent'
            })
        );

        if (customInfo) {
          val = JSON.parse(customInfo.value);
        }
      }

      translation = this.replacePlaceholder(translation, match[0], val);
    }

    return translation;
  },

  extractPlaceholders(translation) {
    return translation.matchAll(/\[\[(.*?)\]\]/g);
  },

  replacePlaceholder(translation, placeholder, replacement) {
    return translation?.replaceAll(placeholder, replacement ?? '') ?? '';
  }
};
