import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"color":"var(--element-background)","flat":"","width":"100%"}},[_c(VSpacer),_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.employee.title)+" "+_vm._s(_vm.employee.lastName)+", "+_vm._s(_vm.employee.firstName)+" ")]),_c('div',{staticClass:"d-flex flex-no-wrap"},[(_vm.imageFileName)?[(_vm.employee)?_c('user-image',{model:{value:(_vm.employee.picture),callback:function ($$v) {_vm.$set(_vm.employee, "picture", $$v)},expression:"employee.picture"}}):_vm._e()]:_vm._e(),_c('div',{staticClass:"v-list-width",staticStyle:{"width":"100%"}},[(_vm.value.fields)?_c(VList,{attrs:{"dense":""}},_vm._l((_vm.value.fields),function(field,index){return _c(VListItem,{key:field.id},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(field.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"overflow":"hidden"}},[_c('details-field',{attrs:{"employee":_vm.employee},model:{value:(_vm.value.fields[index]),callback:function ($$v) {_vm.$set(_vm.value.fields, index, $$v)},expression:"value.fields[index]"}})],1)],1)],1)}),1):_vm._e()],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }