<template>
  <div>
    <v-row>
      <v-col cols="12" md="8" lg="9">
        <v-skeleton-loader
          v-if="loading || !employee || !summaryLayout"
          type="article, list-item, actions"
          class="mt-4"
          loading
        />
        <employee-summary
          v-else
          v-model="employee"
          :layout="summaryLayout"
          :isAdmin="isAdmin"
          @delete="del"
        />
      </v-col>

      <v-col cols="12" md="4" lg="3">
        <v-skeleton-loader
          v-if="loading || !employee || !summaryLayout"
          type="article, list-item, actions"
          class="mt-4"
          loading
        />
        <hp-card v-else class="flex-grow-1" style="height: 100%">
          <h3>{{ $t('employeeDetails.languages') }}</h3>

          <template v-if="employee && !loading">
            <details-tag
              v-for="(skill, index) in languages"
              :key="skill.skillId"
              v-model="languages[index]"
            >
              {{ getSkillTranslation(skill.skillId, skill.skillType) }}
            </details-tag>
          </template>

          <h3 class="mt-4">{{ $t('employeeDetails.skills') }}</h3>

          <template v-if="employee && !loading">
            <details-tag
              v-for="(skill, index) in competencies"
              :key="skill.skillId"
              v-model="competencies[index]"
            >
              {{ getSkillTranslation(skill.skillId, skill.skillType) }}
            </details-tag>
          </template>

          <h3 class="mt-4">Tags</h3>

          <template v-if="employee && !loading">
            <details-tag
              v-for="(tag, index) in tags"
              :key="index"
              v-model="tags[index]"
            >
              {{ tag }}
            </details-tag>
          </template>
        </hp-card>
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-if="loading || !employee || !employeeSettings"
      type="article, list-item, actions"
      class="mt-4"
      loading
    />
    <template v-else>
      <v-row>
        <v-col>
          <hp-card class="flex-grow-1" style="height: 100%">
            <v-row class="align-center">
              <v-col cols="2">{{ $t('onboarding.template') }}</v-col>
              <v-col cols="3">
                <localization-autocomplete
                  v-if="onboardingSettings"
                  v-model="employeeOnboardingSettings"
                  :options="settingsOptions"
                  :readonly="!isAdmin"
                  class="mr-2"
                  flat
                  solo
                  hide-details
                />
              </v-col>
            </v-row>
          </hp-card>
        </v-col>
      </v-row>
      <onboarding-details
        v-model="employeeOnboardingProgress"
        :settings="employeeSettings"
        :readonly="!isAdmin"
        @save="save"
        @finalize="finalize"
      />
    </template>

    <snackbar
      v-model="snackbar"
      color="success"
      icon="mdi-check-circle"
      :text="$t('toast.dataSaved')"
    />

    <snackbar
      v-model="errorMessage"
      color="error"
      icon="mdi-exclamation-triangle"
      :text="$t('toast.errorOcurred')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import employeeSummary from '@/components/employees/employeeDetails/summary.vue';
import onboardingDetails from '@/components/onboarding/details.vue';
import snackbar from '@/components/misc/snackbar.vue';
import detailsTag from '@/components/employees/employeeDetails/details-tag.vue';
import localizationAutocomplete from '@/components/misc/localization-autocomplete.vue';

export default {
  name: 'Onboarding',

  data() {
    return {
      employeeOnboardingSettings: [],
      employeeOnboardingProgress: [],
      dataLoading: true,
      snackbar: false,
      errorMessage: false
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      employee: 'employees/getCurrentEmployee',
      summaryLayout: 'layouts/getEmployeeSummaryLayout',
      onboardingSettings: 'organizations/getOnboardingSettings',
      options: 'employees/getEmployeeDataOptions',
      appLoading: 'loading'
    }),

    isAdmin() {
      return this.currentUser?.isAdmin;
    },

    loading() {
      return this.dataLoading || this.appLoading;
    },

    languages() {
      return this.skills.filter((x) => x.skillType === 1);
    },

    competencies() {
      return this.skills.filter((x) => x.skillType === 2);
    },

    skills() {
      if (!this.employee) {
        return [];
      }

      const skills = [...this.employee?.skills];
      return skills.sort((x, y) => y.skillLevel - x.skillLevel);
    },

    tags() {
      return this.employee?.tags ?? [];
    },

    settingsOptions() {
      const settings = [...this.onboardingSettings];
      settings.forEach((x) => x.translation.forEach((t) => (t.id = x.id)));
      return settings.map((x) => x.translation);
    },

    employeeSettings() {
      if (!this.employee) {
        return null;
      }

      const employeeSetting = this.onboardingSettings.find(
        (x) => x.id === this.employee.onboardingSettingsId
      );

      if (employeeSetting?.translation) {
        employeeSetting.translation.forEach((x) => (x.id = employeeSetting.id));
      }

      return employeeSetting;
    },

    employeeProgress() {
      if (!this.employee) {
        return [];
      }

      return this.employee.onboardingProgress.filter((x) =>
        this.employeeSettings?.steps.find((s) => s.id === x.stepId)
      );
    }
  },

  async mounted() {
    await this.load();
  },

  watch: {
    '$route.params': async function () {
      this.dataLoading = true;
      await this.load();
    },

    employeeOnboardingSettings(newVal) {
      if (newVal && newVal[0]) {
        this.employee.onboardingSettingsId = newVal[0].id;
      }
    },

    employeeSettings(newVal) {
      if (!newVal) {
        return;
      }

      this.employeeOnboardingSettings = newVal.translation;
      this.employeeOnboardingProgress = this.employeeProgress;
    },

    employeeOnboardingProgress: {
      handler() {
        for (const progress of this.employeeOnboardingProgress) {
          const oldProgress = this.employee.onboardingProgress.find(
            (x) => x.stepId == progress.stepId
          );
          if (oldProgress) {
            if (oldProgress.completed != progress.completed) {
              console.log(progress.stepId);
              console.log(oldProgress.completed);
              console.log(progress.completed);
            }
          } else {
            this.employee.onboardingProgress.push(progress);
          }
        }
      },
      deep: true
    }
  },

  methods: {
    ...mapActions({
      fetchEmployee: 'employees/fetchEmployee',
      deleteEmployee: 'employees/deleteEmployee',
      saveOnboardingProgress: 'employees/saveOnboardingProgress',
      finalizeOnboarding: 'employees/finalizeOnboarding',
      setTasksUpdated: 'dashboard/setTasksUpdated'
    }),

    async load() {
      const userId = this.$route.params.id;
      if (!this.employee?.id || this.employee.id != userId) {
        await this.fetchEmployee(userId);
      }

      this.employeeOnboardingSettings = this.employeeSettings?.translation;
      this.employeeOnboardingProgress = this.employeeProgress;
      this.dataLoading = false;
    },

    async del() {
      await this.deleteEmployee(this.employee.id);
      this.$router.push({
        name: 'OnboardingOverview'
      });
    },

    async finalize(data) {
      const saved = await this.save(data, true);
      if (!saved) {
        return;
      }

      const success = await this.finalizeOnboarding(this.employee.userId);
      if (success) {
        this.setTasksUpdated();
        const self = this;
        setTimeout(function () {
          self.$router.push({
            name: 'EmployeeDetails',
            params: { id: self.employee.id }
          });
        }, 1000);
      }

      this.snackbar = success;
      this.errorMessage = !success;
    },

    async save(data, hideSuccess) {
      data.forEach((x) => {
        x.userDataId = this.employee.id;
        if (x.attachments) {
          x.attachments.forEach((y) => (y.userId = this.employee.userId));
        }
      });

      const success = await this.saveOnboardingProgress({
        userId: this.employee.userId,
        settingsId: this.employee.onboardingSettingsId,
        data: data
      });

      if (success) {
        this.setTasksUpdated();
      }

      if (!hideSuccess) {
        this.snackbar = success;
      }

      this.errorMessage = !success;
      return success;
    },

    getSkillTranslation(skillId, skillType) {
      let options = [];
      switch (skillType) {
        case 1:
          options = this.options.languages;
          break;
        case 2:
        default:
          options = this.options.skills;
          break;
      }

      var translation = options.find((x) => x.id === skillId)?.translation;
      if (translation) {
        return this.localize(translation);
      }

      return '';
    }
  },

  components: {
    employeeSummary,
    onboardingDetails,
    snackbar,
    detailsTag,
    localizationAutocomplete
  }
};
</script>
