export const layoutEnums = Object.freeze({
  fieldTypes: {
    FIRSTNAME: 1,
    LASTNAME: 2,
    TITLE: 3,
    GENDER: 4,
    EMAIL: 5,
    POSITION: 10,
    HIERARCHY: 11,
    LOCATION: 12,
    NATION: 13,
    EMPLOYMENTSTARTDATE: 14,
    SUPERVISOR: 15,
    CUSTOM: 100
  },

  valueTypes: {
    TEXT: 1,
    MULTILINGUAL: 2,
    NUMERIC: 3,
    DATE: 4,
    DATETIME: 5,
    MULTIPLEOPTIONS: 6,
    MULTILINGUALMULTIPLEOPTIONS: 7,
    FILEUPLOADSINGLE: 8,
    FILEUPLOADMULTIPLE: 9,
    CURRENCY: 10
  }
});
