import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"employees"},[_c('hp-card',{staticClass:"hero-bar"},[_c(VTabs,{attrs:{"align-with-title":"","grow":""},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c(VTabsSlider,{attrs:{"color":"var(--v-primary-base)"}}),_c(VTab,[_vm._v(" "+_vm._s(_vm.$t('statistics.reportTab'))+" ")]),_c(VTab,{attrs:{"disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('statistics.name'))+" ")])],1),_c(VTabsItems,{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c(VTabItem,[_c(VCard,{staticClass:"mt-1 py-8",attrs:{"flat":""}},[_c(VCardText,[_c(VRow,[_c(VCol,[_vm._v(" "+_vm._s(_vm.$t('statistics.reportInfoIntro'))+" ")])],1),_c(VRow,[_c(VCol,[_vm._v(" "+_vm._s(_vm.$t('statistics.reportInfoOutro'))+" ")])],1),_c(VRow,[_c(VCol,{staticClass:"d-flex align-center text-center",attrs:{"cols":"9"}},[_c('hp-button',{attrs:{"prepend-icon":"mdi-file-excel"},on:{"click":_vm.createUserReport}},[_vm._v(" "+_vm._s(_vm.$t('statistics.reportDownload'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }