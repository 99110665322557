<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on: dialog, attrs }">
      <hp-button class="mr-2" :on="{ ...dialog }" v-bind="attrs">
        <slot name="button">
          <v-icon>mdi-close</v-icon>
          {{ $t('employee.deleteEmployee') }}
        </slot>
      </hp-button>
    </template>

    <hp-widget icon="mdi-account-minus">
      <template v-slot:title>
        {{ $t('employee.deleteEmployee') }}
      </template>

      <template v-slot:actions>
        <hp-button plain icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </hp-button>
      </template>

      <v-form>
        {{ $t('employee.deleteEmployeeConfirmation') }}
      </v-form>

      <template v-slot:buttons>
        <hp-button @click="cancel">
          <slot name="close-button">
            <v-icon class="mr-2">mdi-close</v-icon>
            {{ $t('cancel') }}</slot
          >
        </hp-button>
        <hp-button primary @click="confirm">
          <slot name="confirm-button">
            <v-icon class="mr-2">mdi-check</v-icon>
            {{ $t('delete') }}</slot
          >
        </hp-button>
      </template>

      <!-- <v-card-actions class="pa-4 pt-0">
        <v-spacer></v-spacer>
        
      </v-card-actions> -->
    </hp-widget>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    showDialog: false
  }),

  methods: {
    cancel() {
      this.showDialog = false;
    },

    async confirm() {
      this.$emit('delete');
      this.showDialog = false;
    }
  }
};
</script>
