<template>
  <v-row v-if="fieldValue !== undefined" class="align-center">
    <v-col cols="12" sm="6" md="5" v-if="showName">
      <localization-text-field
        v-model="value.translation"
        :readonly="readonly || value.fieldType !== this.fieldTypes.CUSTOM"
        dense
      />
    </v-col>
    <v-col cols="12" sm="6" md="6">
      <component
        :is="fieldComponent"
        v-model="fieldValue"
        :options="fieldOptions"
        readonly
        enabled
      ></component>
    </v-col>
    <v-col v-if="!readonly" cols="12" sm="6" md="1">
      <v-btn small icon @click="deleteField">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import datepicker from '@/components/misc/datepicker.vue';
import datetimePicker from '@/components/misc/datetime-picker.vue';
import numericField from '@/components/misc/numeric-field.vue';
import localizationTextField from '@/components/misc/localization-text-field.vue';
import localizationAutocomplete from '@/components/misc/localization-autocomplete.vue';
import singleLanguageTextField from '@/components/misc/single-language-text-field.vue';
import singleLanguageAutocomplete from '@/components/misc/single-language-autocomplete.vue';
import { mapGetters } from 'vuex';
import { layoutEnums } from '@/components/enums/layouts.js';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    employee: {
      type: Object,
      required: true
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    },

    showName: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      fieldTypes: layoutEnums.fieldTypes,
      valueTypes: layoutEnums.valueTypes,
      fieldValue: undefined,
      fieldOptions: []
    };
  },

  computed: {
    ...mapGetters({
      options: 'employees/getEmployeeDataOptions'
    }),

    locationOptions() {
      return this.options?.locations ?? [];
    },

    nationOptions() {
      return this.options?.nations ?? [];
    },

    positionOptions() {
      return this.options?.positions ?? [];
    },

    hierarchyOptions() {
      return this.options?.hierarchies ?? [];
    },

    fieldComponent() {
      switch (this.value.valueType) {
        case this.valueTypes.MULTILINGUAL:
          return 'localization-text-field';
        case this.valueTypes.DATE:
          return 'datepicker';
        case this.valueTypes.DATETIME:
          return 'datetime-picker';
        case this.valueTypes.NUMERIC:
          return 'numeric-field';
        case this.valueTypes.MULTILINGUALMULTIPLEOPTIONS:
          return 'localization-autocomplete';
        case this.valueTypes.MULTIPLEOPTIONS:
          return 'single-language-autocomplete';
        case this.valueTypes.TEXT:
        default:
          return 'single-language-text-field';
      }
    },

    defaultFieldValue() {
      switch (this.value.valueType) {
        case this.valueTypes.MULTILINGUAL:
        case this.valueTypes.MULTILINGUALMULTIPLEOPTIONS:
          return [];
        case this.valueTypes.TEXT:
        case this.valueTypes.DATE:
        case this.valueTypes.DATETIME:
        case this.valueTypes.NUMERIC:
        case this.valueTypes.MULTIPLEOPTIONS:
        default:
          return '';
      }
    }
  },

  async mounted() {
    this.setData();
  },

  methods: {
    setData() {
      switch (this.value.fieldType) {
        case this.fieldTypes.FIRSTNAME:
          this.fieldValue = this.employee?.firstName;
          break;
        case this.fieldTypes.LASTNAME:
          this.fieldValue = this.employee?.lastName;
          break;
        case this.fieldTypes.TITLE:
          this.fieldValue = this.employee?.title;
          break;
        case this.fieldTypes.GENDER:
          this.fieldValue = this.employee?.gender;
          break;
        case this.fieldTypes.EMAIL:
          this.fieldValue = this.employee?.email;
          break;
        case this.fieldTypes.POSITION:
          this.fieldValue = this.employee?.position?.translation;
          this.fieldOptions = this.positionOptions.map((x) => x.translation);
          break;
        case this.fieldTypes.HIERARCHY:
          this.fieldValue = this.employee?.hierarchy?.translation;
          this.fieldOptions = this.hierarchyOptions.map((x) => x.translation);
          break;
        case this.fieldTypes.LOCATION:
          this.fieldValue = this.employee?.location?.translation;
          this.fieldOptions = this.locationOptions.map((x) => x.translation);
          break;
        case this.fieldTypes.NATION:
          this.fieldValue = this.employee?.nation?.translation;
          this.fieldOptions = this.nationOptions.map((x) => x.translation);
          break;
        case this.fieldTypes.EMPLOYMENTSTARTDATE:
          this.fieldValue = this.employee?.employmentStartDate;
          break;
        case this.fieldTypes.SUPERVISOR:
          this.fieldValue = this.employee?.supervisorName;
          break;
        case this.fieldTypes.CUSTOM:
          this.fieldValue = this.getCustomFieldValue();
          this.fieldOptions = this.getCustomFieldOptions();
          break;
      }
    },

    getCustomFieldValue() {
      const customInfo = this.employee?.customInformation?.find(
        (x) => x.customFieldId === this.value.id
      );

      if (customInfo?.value) {
        return JSON.parse(customInfo.value);
      }

      return this.defaultFieldValue;
    },

    getCustomFieldOptions() {
      return [];
    },

    deleteField() {
      this.$emit(
        'delete',
        this.value.id,
        this.value.columnIndex,
        this.value.rowIndex
      );
    }
  },

  components: {
    datepicker,
    datetimePicker,
    numericField,
    localizationTextField,
    localizationAutocomplete,
    singleLanguageTextField,
    singleLanguageAutocomplete
  }
};
</script>
