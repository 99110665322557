<template>
  <div class="file-input-wrapper">
    <input
      ref="fileInput"
      type="file"
      class="input-file"
      @change="filesChange($event.target.files)"
    />
    <hp-button
      v-show="!file && !readonly"
      :class="buttonClass"
      :disabled="fileUploading"
      @click="startUpload()"
    >
      <template v-if="!fileUploading">
        <v-icon dense> {{ buttonIcon }} </v-icon>
        {{ buttonLabel }}
      </template>
      <template v-else>
        <i class="fa fa-spin fa-refresh"></i>
        {{ $t('files.uploading') }}
      </template>
    </hp-button>
    <template v-if="file">
      <a @click.stop.prevent="downloadFile">
        <v-icon> {{ fileIcon }} </v-icon>
        {{ file.fileName }}
      </a>
      <hp-button plain icon v-if="showDeleteButton" @click="deleteUploadedFile">
        <v-icon>mdi-close</v-icon>
      </hp-button>
    </template>
    <span v-else-if="showEmpty && readonly">
      {{ $t('files.noFileUploaded') }}
    </span>
  </div>
</template>

<script>
//const deleteUrlProp = 'deleteEndpoint';
import api from '@/helpers/api.js';
import { mapActions } from 'vuex';

export default {
  name: 'TextInput',
  $_veeValidate: {
    value() {
      //return this.$el.value;
      return this.file;
    },

    name() {
      return this.name;
    }
  },

  props: {
    value: {
      type: Object,
      default: () => {}
    },

    uploadOptions: {
      type: Object,
      required: false,
      default: () => {}
    },

    name: {
      type: String,
      required: false,
      default: ''
    },

    readonly: {
      type: Boolean,
      required: false,
      default: false
    },

    employeeId: {
      type: String,
      required: false,
      default: null
    },

    showEmpty: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: () => ({
    fileUploading: false,
    currentButtonLabel: null
  }),

  computed: {
    buttonClass() {
      return this.uploadOptions?.buttonClass ?? 'btn btn-default';
    },

    buttonIcon() {
      return this.uploadOptions?.icon ?? 'mdi-upload';
    },

    buttonLabel() {
      return (
        this.currentButtonLabel ??
        this.uploadOptions?.buttonLabel ??
        this.$t('files.uploadFile')
      );
    },

    file() {
      if (!this.value?.fileName) {
        return null;
      }

      return this.value;
    },

    fileUrl() {
      if (!this.file?.filePath) {
        return '';
      }

      return JSON.parse(this.file.filePath);
    },

    fileIcon() {
      if (!this.file) {
        return '';
      }

      let iconType = 'mdi-file';
      const fName = this.file.fileName.toLowerCase();
      if (fName.endsWith('.pdf')) {
        iconType += '-pdf-box';
      } else if (fName.endsWith('.doc') || fName.endsWith('.docx')) {
        iconType += '-word';
      } else if (
        fName.endsWith('.jpg') ||
        fName.endsWith('.jpeg') ||
        fName.endsWith('.png') ||
        fName.endsWith('.bmp') ||
        fName.endsWith('.gif')
      ) {
        iconType += '-image';
      } else if (fName.endsWith('.xls') || fName.endsWith('.xlsx')) {
        iconType += '-excel';
      }

      return iconType;
    },

    showDeleteButton() {
      return !this.readonly;
      //deleteUrlProp in this.uploadOptions &&
      //this.uploadOptions[deleteUrlProp].length > 0
    }
  },

  methods: {
    ...mapActions({
      saveFile: 'files/saveFile',
      deleteFile: 'files/deleteFile'
    }),
    async filesChange(fileList) {
      this.fileUploading = true;
      if (fileList.length > 0) {
        const file = fileList[0];
        const reader = new FileReader();
        reader.onerror = (err) => {
          console.log(err);
          this.$emit('input', null);
        };

        reader.onload = async () => {
          const parts = reader.result.split(';');
          const contentType = parts[0].replace('data:', '');
          const fileContent = parts[1].replace('base64,', '');

          const data = {
            fileName: file.name,
            fileContent: fileContent,
            contentType: contentType
          };

          const bucket = this.employeeId ?? 'public';
          const displayFile = await this.saveFile({
            bucket: bucket,
            data: data,
            employeeId: this.employeeId
          });

          this.$emit('input', displayFile);
        };

        reader.readAsDataURL(file);
      }

      this.fileUploading = false;
    },

    async deleteUploadedFile() {
      await this.deleteFile({
        bucket: this.employeeId ?? 'public',
        fileName: this.file.fileName
      });

      this.$emit('input', null);
    },

    startUpload() {
      this.$refs.fileInput.click();
    },

    async downloadFile() {
      const data = await api.download('/File', {
        bucket: this.fileUrl.Bucket,
        fileName: this.fileUrl.FileName
      });

      const file = new File([data], this.fileUrl.FileName);
      const linkElement = document.createElement('a');
      linkElement.href = URL.createObjectURL(file);
      linkElement.download = this.fileUrl.FileName;
      linkElement.click();
      URL.revokeObjectURL(linkElement.href);
    }
  }
};
</script>

<style scoped>
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 0;
  height: 0;
  position: absolute;
  cursor: pointer;
}

.file-input-wrapper {
  display: inline-block;
}
</style>
