<template>
  <v-tab-item>
    <hp-card class="employee-details__groups">
      <v-row>
        <v-col class="no-border-bottom">
          <div class="employee-details__grouping pl-2">
            <h5>
              {{ $t('employeeDetails.languages') }}
            </h5>
            <div>
              <template v-for="lang in languages">
                <v-row :key="lang.skillId" class="align-center editable">
                  <v-col cols="12" sm="6" :md="fieldColMd" class="col-label">
                    {{ getLanguageTranslation(lang.skillId) }}
                  </v-col>
                  <v-col cols="12" sm="6" :md="selectColMd">
                    <v-select
                      v-model="lang.skillLevel"
                      :items="languageLevels"
                      :readonly="readonly"
                      solo
                      flat
                      hide-details
                      enabled
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="!readonly">
                    <hp-button @click="deleteSkill(lang.skillId)">
                      {{ $t('delete') }}
                    </hp-button>
                  </v-col>
                </v-row>
              </template>
              <v-row class="align-center" v-if="!readonly">
                <v-col cols="12" sm="6" md="9" class="col-label">
                  <localization-auto-complete
                    v-model="newLanguage"
                    :options="languageOptions"
                    solo
                    flat
                    hide-details
                    enabled
                    :readonly="readonly"
                    :placeholder="$t('skills.newLanguage')"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <hp-button @click="addLanguage">
                    {{ $t('skills.addSkill') }}
                  </hp-button>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
        <v-col class="no-border-right no-border-bottom">
          <div class="employee-details__grouping">
            <h5>
              {{ $t('employeeDetails.skills') }}
            </h5>
            <div>
              <template v-for="comp in competencies">
                <v-row :key="comp.skillId" class="align-center editable">
                  <v-col cols="12" sm="6" :md="fieldColMd" class="col-label">
                    {{ getSkillTranslation(comp.skillId) }}
                  </v-col>
                  <v-col cols="12" sm="6" :md="selectColMd">
                    <v-select
                      v-model="comp.skillLevel"
                      :items="competencyLevels"
                      :readonly="readonly"
                      solo
                      flat
                      hide-details
                      enabled
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="!readonly">
                    <hp-button @click="deleteSkill(comp.skillId)">
                      {{ $t('delete') }}
                    </hp-button>
                  </v-col>
                </v-row>
              </template>
              <v-row class="align-center" v-if="!readonly">
                <v-col cols="12" sm="6" md="9" class="col-label">
                  <localization-auto-complete
                    v-model="newCompetency"
                    :options="skillOptions"
                    solo
                    flat
                    hide-details
                    enabled
                    :readonly="readonly"
                    :placeholder="$t('skills.newCompetency')"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <hp-button @click="addCompetency">
                    {{ $t('skills.addSkill') }}
                  </hp-button>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
    </hp-card>
  </v-tab-item>
</template>

<script>
import localizationAutoComplete from '../../misc/localization-autocomplete.vue';
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    value: {
      type: Array,
      required: true
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: () => ({
    newLanguage: null,
    newCompetency: null
  }),

  computed: {
    ...mapGetters({
      options: 'employees/getEmployeeDataOptions'
    }),

    fieldColMd() {
      return this.readonly ? 5 : 3;
    },

    selectColMd() {
      return this.readonly ? 7 : 5;
    },

    skillOptions() {
      return this.options?.skills
        ?.filter((x) => !this.competencies.find((y) => x.id === y.skillId))
        .map((x) => x.translation ?? []);
    },

    languageOptions() {
      return this.options?.languages
        ?.filter((x) => !this.languages.find((y) => x.id === y.skillId))
        .map((x) => x.translation ?? []);
    },

    languages() {
      return this.value.filter((x) => x.skillType === 1);
    },

    competencies() {
      return this.value.filter((x) => x.skillType === 2);
    },

    languageLevels() {
      const translations = this.$t('skills.languageLevels');
      return Object.keys(translations).map((x) => ({
        text: translations[x],
        value: parseInt(x)
      }));
    },

    competencyLevels() {
      const translations = this.$t('skills.competencyLevels');
      return Object.keys(translations).map((x) => ({
        text: translations[x],
        value: parseInt(x)
      }));
    }
  },

  methods: {
    addLanguage() {
      if (this.newLanguage?.length > 0 ?? false) {
        this.addSkill(this.newLanguage[0].id, 1, 0);
        this.newLanguage = null;
      }
    },

    addCompetency() {
      if (this.newCompetency?.length > 0 ?? false) {
        this.addSkill(this.newCompetency[0].id, 2, 1);
        this.newCompetency = null;
      }
    },

    addSkill(skillId, skillType, level) {
      this.value.push({
        id: uuidv4(),
        skillType: skillType,
        skillId: skillId,
        skillLevel: level
      });
    },

    deleteSkill(skillId) {
      const skills = this.value.filter((x) => x.skillId !== skillId);
      this.$emit('input', skills);
    },

    getSkillTranslation(skillId) {
      var translation = this.options.skills.find(
        (x) => x.id === skillId
      )?.translation;

      if (translation) {
        return this.localize(translation);
      }

      return '';
    },

    getLanguageTranslation(languageId) {
      var translation = this.options.languages.find(
        (x) => x.id === languageId
      )?.translation;

      if (translation) {
        return this.localize(translation);
      }

      return '';
    }
  },

  components: {
    localizationAutoComplete
  }
};
</script>
