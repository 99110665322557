<template>
  <div>
    <v-sheet
      :color="numStepsCompleted === numSteps ? '#93c300' : 'primary'"
      class="white--text pa-4 py-8 mb-6 mt-6"
      style="border-radius: 10px"
    >
      <div class="d-flex align-center">
        <div>
          <strong>{{ numStepsCompleted }}/{{ numSteps }}</strong>
        </div>

        <div class="flex-grow-1 px-8">
          <v-progress-linear
            :value="percentage"
            :color="numStepsCompleted === numSteps ? 'white' : '#00376d'"
            background-color="white"
            height="10"
            rounded
          ></v-progress-linear>
        </div>

        <div>
          <strong>{{ percentage }}%</strong>
        </div>
      </div>
    </v-sheet>

    <v-card class="hp-card" elevation="0">
      <v-card-text>
        <v-row class="onboarding-header">
          <v-col cols="1"> {{ $t('status') }} </v-col>
          <v-col cols="3"> {{ $tc('onboarding.steps', 1) }} </v-col>
          <v-col cols="4"> {{ $t('onboarding.additionalData') }} </v-col>
          <v-col cols="2"> {{ $t('onboarding.completedOn') }} </v-col>
          <v-col cols="2"> {{ $t('onboarding.completedBy') }} </v-col>
        </v-row>

        <!-- <v-timeline v-if="!loading" dense> -->
        <template v-if="!loading">
          <onboarding-step
            v-for="(step, index) in settings.steps"
            :key="step.id"
            :settings="step"
            v-model="userProgress[index]"
            :readonly="readonly"
          />
        </template>
        <!-- </v-timeline> -->

        <v-row>
          <v-col cols="12" class="text-right">
            <hp-button
              prependIcon="mdi-content-save-outline"
              :disabled="readonly"
              class="mr-2"
              @click="save"
            >
              {{ $t('save') }}
            </hp-button>

            <hp-button
              v-if="employee && employee.onboarding && !readonly"
              primary
              prependIcon="mdi-check"
              :disabled="!onboardingComplete"
              @click="finalizeOnboarding"
            >
              {{ $t('onboarding.finalize') }}
            </hp-button>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import onboardingStep from '@/components/onboarding/onboardingStep.vue';
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    settings: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      userProgress: [],
      loading: true
    };
  },

  computed: {
    ...mapGetters({
      employee: 'employees/getCurrentEmployee'
    }),

    numStepsCompleted() {
      return this.numOptionalStepsCompleted + this.numMandatoryStepsCompleted;
    },

    numSteps() {
      return this.numOptionalSteps + this.numMandatorySteps;
    },

    numMandatorySteps() {
      return this.settings?.steps?.filter((x) => !x.optional).length ?? 0;
    },

    numMandatoryStepsCompleted() {
      return (
        this.userProgress
          ?.filter((x) => x.completed)
          ?.filter((x) => !this.isOptional(x.stepId))?.length ?? 0
      );
    },

    numOptionalSteps() {
      return this.settings?.steps?.filter((x) => x.optional).length ?? 0;
    },

    numOptionalStepsCompleted() {
      return (
        this.userProgress
          ?.filter((x) => x.completed)
          ?.filter((x) => this.isOptional(x.stepId))?.length ?? 0
      );
    },

    percentage() {
      return ((this.numStepsCompleted * 100) / this.numSteps).toFixed(0);
    },

    onboardingComplete() {
      return this.numMandatoryStepsCompleted === this.numMandatorySteps;
    }
  },

  mounted() {
    this.userProgress = this.getUserValues();
    this.loading = false;
  },

  watch: {
    'value.onboardingProgress': {
      handler() {
        this.userProgress = this.getUserValues();
      }
    },
    settings() {
      this.userProgress = this.getUserValues();
    }
  },

  methods: {
    getUserValues() {
      return this.settings.steps.map((x) => this.getUserValue(x.id));
    },

    getUserValue(stepId) {
      return (
        this.value?.find((x) => x.stepId === stepId) ?? {
          stepId: stepId,
          completed: false,
          completionDate: null,
          comment: '',
          attachments: []
        }
      );
    },

    isOptional(stepId) {
      return this.settings?.steps?.find((x) => x.id === stepId)?.optional;
    },

    save() {
      if (!this.readonly) {
        this.$emit('save', this.userProgress);
      }
    },

    finalizeOnboarding() {
      if (
        this.employee?.onboarding &&
        !this.readonly &&
        this.onboardingComplete
      ) {
        this.$emit('finalize', this.userProgress);
      }
    }
  },

  components: {
    onboardingStep
  }
};
</script>
<style scoped>
.onboarding-header {
  font-weight: bold;
}

.v-card .row {
  flex-grow: 1;
}
</style>
