<template>
  <div>
    <template v-if="readonly"> {{ number }} </template>
    <v-text-field
      ref="field"
      v-else
      v-model="number"
      v-bind="$attrs"
      :rules="rules"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    },

    format: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data() {
    return {
      number: '',
      rules: [
        (v) => !!v || this.$t('validationErrors.numericInput'),
        (v) => !isNaN(parseFloat(v)) || this.$t('validationErrors.numericInput')
      ]
    };
  },

  computed: {
    ...mapGetters({
      languageKey: 'languageKey'
    }),

    formattedValue() {
      if (!this.value) {
        return '';
      }

      return Intl.NumberFormat(this.languageKey, this.format).format(
        this.value
      );
    },

    unformattedNumber() {
      if (!this.number) {
        return '';
      }

      var thousandSeparator = Intl.NumberFormat(this.languageKey)
        .format(1000)
        .replace(/\p{Number}/gu, '');

      var decimalSeparator = Intl.NumberFormat(this.languageKey)
        .format(0.1)
        .replace(/\p{Number}/gu, '');

      var unformatted = this.number
        .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
        .replace(new RegExp(`\\${decimalSeparator}`), '.');

      if (isNaN(unformatted)) {
        return this.value;
      }

      return unformatted;
    }
  },

  watch: {
    number() {
      if (this.number) {
        this.$emit('input', this.unformattedNumber);
      }
    },

    value() {
      const cursorPosition = this.$refs.field.selectionStart;
      console.log(this.$refs.field);
      console.log(cursorPosition);
      this.number = this.formattedValue;
      this.$refs.field.selectionEnd = cursorPosition;
    }
  },

  mounted() {
    this.number = this.formattedValue;
  }
};
</script>

<style lang="scss" scoped>
::v-deep button.v-btn {
  padding: 0 6px;
  min-width: 40px;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  display: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
