<template>
  <v-card flat class="segmented mt-4">
    <v-card-title>
      <v-col cols="12" md="11">
        <localization-text-field
          v-model="value.translation"
          :readonly="readonly"
          dense
        />
      </v-col>
      <v-col v-if="!readonly" cols="12" md="1">
        <v-btn icon @click="deleteGrouping">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-card-title>
    <v-card-text v-if="value.fields">
      <template v-for="(field, index) in value.fields">
        <details-field
          v-model="value.fields[index]"
          :employee="employee"
          :key="field.id"
          :readonly="readonly"
          showName
          @delete="deleteField"
        />
      </template>
      <v-container class="py-3 px-0" v-if="!readonly">
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn color="primary" class="white--text" @click="createField">
              {{ $t('layouts.addField') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import localizationTextField from '@/components/misc/localization-text-field.vue';
import detailsField from '@/components/layouts/employeeDetails/details-field.vue';
import { layoutEnums } from '@/components/enums/layouts.js';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    employee: {
      type: Object,
      required: true
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      fieldTypes: layoutEnums.fieldTypes,
      valueTypes: layoutEnums.valueTypes
    };
  },

  methods: {
    deleteField(fieldId, columnIndex, rowIndex) {
      this.value.fields = this.value.fields.filter((x) => x.id !== fieldId);
      this.value.fields.forEach(
        (x) =>
          x.columnIndex === columnIndex && x.rowIndex > rowIndex && x.index--
      );
    },

    createField() {
      this.value.fields.push({
        index: this.value.fields.length,
        translation: [
          { languageKey: 'de-DE', translation: 'Neues Feld' },
          { languageKey: 'en-US', translation: 'New field' }
        ],
        fieldType: this.fieldTypes.CUSTOM,
        valueType: this.valueTypes.TEXT
      });
    },

    deleteGrouping() {
      this.$emit('delete', this.value.id, this.value.index);
    }
  },

  components: {
    detailsField,
    localizationTextField
  }
};
</script>
<style scoped>
.v-card,
.v-card__text {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.v-card .row {
  flex-grow: 0;
}
</style>
