import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('hp-button',_vm._b({staticClass:"mr-2",attrs:{"on":Object.assign({}, dialog)}},'hp-button',attrs,false),[_vm._t("button",function(){return [_c(VIcon,[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$t('employee.deleteEmployee'))+" ")]})],2)]}}],null,true),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('hp-widget',{attrs:{"icon":"mdi-account-minus"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('employee.deleteEmployee'))+" ")]},proxy:true},{key:"actions",fn:function(){return [_c('hp-button',{attrs:{"plain":"","icon":""},on:{"click":_vm.cancel}},[_c(VIcon,[_vm._v("mdi-close")])],1)]},proxy:true},{key:"buttons",fn:function(){return [_c('hp-button',{on:{"click":_vm.cancel}},[_vm._t("close-button",function(){return [_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$t('cancel')))]})],2),_c('hp-button',{attrs:{"primary":""},on:{"click":_vm.confirm}},[_vm._t("confirm-button",function(){return [_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(_vm.$t('delete')))]})],2)]},proxy:true}],null,true)},[_c(VForm,[_vm._v(" "+_vm._s(_vm.$t('employee.deleteEmployeeConfirmation'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }