<template>
  <v-row class="onboarding-step">
    <v-col cols="1">
      <v-switch
        v-model="value.completed"
        inset
        hide-details
        background-color="ffffff"
        class="mt-0 pt-0"
        :class="{ optional: settings.optional }"
      >
      </v-switch>
    </v-col>
    <v-col cols="3" class="d-flex">
      <div
        style="color: var(--primary); font-weight: bold"
        v-html="stepLabel"
      ></div>

      <v-spacer></v-spacer>
      <hp-label v-if="settings.optional" color="orange"> Optional </hp-label>
    </v-col>

    <v-col cols="4">
      <div v-if="settings.allowComment">
        <v-textarea
          v-model="value.comment"
          :disabled="readonly"
          solo
          flat
          :placeholder="$t('comment')"
          rows="1"
          auto-grow
          hide-details
        />
      </div>

      <div v-if="settings.allowFileUpload" class="mt-2">
        <file-upload-multiple
          v-model="value.attachments"
          :name="$t('files.uploadFile')"
          :readonly="readonly"
        />
      </div>
    </v-col>

    <v-col cols="2" class="d-flex">
      <template>
        <template v-if="readonly">
          {{ this.displayDate }}
        </template>
        <template v-else>
          <div class="d-flex">
            <datepicker
              v-model="value.completionDate"
              :readonly="readonly"
              solo
              flat
              hide-details
              enabled
            />
          </div>
        </template>
      </template>
    </v-col>

    <v-col cols="2" class="d-flex">
      <div class="d-flex">
        <single-language-autocomplete
          v-model="stepEmployee"
          :options="employeeOptions"
          :readonly="readonly"
          solo
          flat
          hide-details
        />
      </div>
    </v-col>
  </v-row>
  <!-- <v-timeline-item
    :color="dotColor"
    :icon="dotIcon"
    class="mb-4"
    @click.native="itemClicked"
  >
    <v-row
      class="onboarding-step-labels font-weight-medium mb-2 align-center"
      dense
    >
      <v-col cols="6" md="6">
        <div v-html="stepLabel"></div>

        <div v-if="settings.allowComment">
          <v-textarea
            v-model="value.comment"
            dense
            :disabled="readonly || value.completed"
            solo
            flat
            :placeholder="$t('comment')"
            rows="2"
            auto-grow
            hide-details
          />
        </div>

        <div v-if="settings.allowFileUpload" class="mt-2">
          <file-upload-multiple
            v-model="value.attachments"
            :name="$t('files.uploadFile')"
            :readonly="readonly || value.completed"
          />
        </div>
      </v-col>

      <v-col cols="6" lg="4" offset-lg="2" class="d-flex justify-end">
        <template v-if="value.completionDate">
          <template v-if="readonly">
            {{ this.$t('onboarding.completedOn') }}
            {{ this.displayDate }}
          </template>
          <template v-else>
            <div class="d-flex align-center">
              {{ this.$t('onboarding.completedOn') }}
              <datepicker
                v-model="value.completionDate"
                :readonly="readonly"
                class="ml-4"
                dense
                solo
                flat
                hide-details
                enabled
              />
            </div>
          </template>
        </template>
      </v-col>
    </v-row>
  </v-timeline-item> -->
</template>

<script>
import singleLanguageAutocomplete from '@/components/misc/single-language-autocomplete.vue';
import fileUploadMultiple from '@/components/misc/file-upload-multiple.vue';
import datepicker from '@/components/misc/datepicker.vue';
import placeholdersHelper from '@/helpers/placeholders-helper.js';
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    settings: {
      type: Object,
      required: true
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: () => ({
    stepEmployee: null
  }),

  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      employee: 'employees/getCurrentEmployee',
      employees: 'employees/getEmployees',
      languageKey: 'languageKey',
      dateFormat: 'dateFormat'
    }),

    stepLabel() {
      const label = this.localize(this.settings.translation);
      return placeholdersHelper.replaceEmployeePlaceholders(
        label,
        this.employee
      );
    },

    dotColor() {
      if (this.value.completed) {
        return 'green';
      } else if (this.settings.optional) {
        return 'orange';
      } else {
        return 'red';
      }
    },

    dotIcon() {
      if (this.value.completed) {
        return 'mdi-check';
      } else if (this.settings.optional) {
        return 'mdi-help';
      } else {
        return 'mdi-close';
      }
    },

    displayDate() {
      const date = new Date(this.value.completionDate);
      return date.toLocaleDateString(this.languageKey, this.dateFormat);
    },

    employeeOptions() {
      return (
        this.employees.map((x) => ({
          id: x.id,
          translation: `${x.firstName} ${x.lastName}`
        })) ?? []
      );
    }
  },

  watch: {
    'value.completed': {
      handler(newVal) {
        if (newVal) {
          if (!this.value.completionDate) {
            const date = new Date();
            this.value.completionDate = date.toISOString();
          }

          if (!this.value.completionUserId) {
            this.stepEmployee = this.getEmployee(this.currentUser.id);
          }
        }
      }
    },

    stepEmployee(newVal) {
      this.value.completionUserId = newVal?.id;
    }
  },

  mounted() {
    this.stepEmployee = this.getEmployee(this.value.completionUserId);
  },

  methods: {
    itemClicked(event) {
      if (
        event.target.classList.contains('v-timeline-item__inner-dot') ||
        event.target.classList.contains('v-icon')
      ) {
        this.value.completed = !this.value.completed;
      }
    },

    getEmployee(id) {
      return this.employees.find((x) => x.id === id);
    }
  },

  components: {
    fileUploadMultiple,
    datepicker,
    singleLanguageAutocomplete
  }
};
</script>
<style scoped>
.col-file-upload {
  margin-top: -32px;
}

.onboarding-step {
  border-bottom: 2px dotted var(--border-muted);
}

.col {
  padding: 24px 12px;
}

::v-deep .v-input.v-input--switch {
  input[aria-checked='false'] ~ .v-input--switch__thumb {
    &::before {
      content: '\f0156';
      display: block;
      font-family: 'Material Design Icons';
      color: #000;
    }
  }

  &.optional {
    input[aria-checked='false'] ~ .v-input--switch__thumb {
      background-color: rgb(255, 161, 0);

      &::before {
        content: '\f02d6';
        color: #fff;
      }
    }
  }

  input[aria-checked='true'] ~ .v-input--switch__thumb::before {
    content: '\f012c';
    display: block;
    font-family: 'Material Design Icons';
    color: #fff;
  }
}
</style>
