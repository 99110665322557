<template>
  <div>
    <template v-if="readonly"> {{ selectionOption.translation }} </template>
    <v-autocomplete
      v-else
      v-model="selectionOption"
      :items="options"
      return-object
      v-bind="$attrs"
      item-value="id"
      item-text="translation"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {}
    },

    options: {
      type: Array,
      required: false,
      default: () => []
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      selectionOption: null
    };
  },

  watch: {
    selectionOption() {
      this.$emit('input', this.selectionOption);
    },

    value() {
      this.selectionOption = this.value;
    }
  },

  mounted() {
    if (this.value) {
      this.selectionOption = this.value;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep button.v-btn {
  padding: 0 6px;
  min-width: 40px;
}
</style>
