<template>
  <v-col cols="12" :sm="currentSize.sm" :md="currentSize.md">
    <hp-widget icon="mdi-calendar">
      <template v-slot:title>{{ $t('dashboard.widgets.calendar') }}</template>

      <template v-slot:actions>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <hp-button
              small
              plain
              icon
              @click="resize({ type: 'calendar' })"
              v-on="on"
            >
              <v-icon>mdi-arrow-expand-horizontal</v-icon>
            </hp-button>
          </template>

          <span>{{ $t('dashboard.resize') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <hp-button small plain icon class="drag-widget" v-on="on">
              <v-icon>mdi-arrow-expand-all</v-icon>
            </hp-button>
          </template>

          <span>{{ $t('dashboard.move') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <hp-button
              small
              plain
              icon
              @click="close({ type: 'calendar' })"
              v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </hp-button>
          </template>

          <span>{{ $t('dashboard.remove') }}</span>
        </v-tooltip>
      </template>

      <v-app-bar flat>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              icon
              @click="type = 'month'"
              v-bind="attrs"
              v-on="on"
              class="ml-0"
            >
              <v-icon>mdi-calendar-month</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('dashboard.calendar.monthView') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon @click="type = 'week'" v-bind="attrs" v-on="on">
              <v-icon>mdi-calendar-week</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('dashboard.calendar.weekView') }}</span>
        </v-tooltip>

        <v-spacer />

        <v-toolbar-title class="text-h6 pl-0">{{ dateLabel }}</v-toolbar-title>
        <v-spacer />
        <v-btn small icon @click="prev">
          <v-icon style="cursor: pointer">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn small icon @click="next">
          <v-icon style="cursor: pointer">mdi-chevron-right</v-icon>
        </v-btn>
      </v-app-bar>

      <v-calendar
        ref="calendar"
        v-model="focus"
        :weekday-format="weekday"
        :month-format="month"
        color="secondary"
        :events="visibleEvents"
        :event-color="getEventColor"
        :event-more-text="
          $tc('dashboard.calendar.moreEvents', '{0}', { count: '{0}' })
        "
        :type="type"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        @click:event="exportDate"
        @click:more="viewDay"
        @click:date="viewDay"
        @mouseenter:event="openEventPopup"
        @mouseleave:event="closeEventPopup"
        @change="updateRange"
      ></v-calendar>
    </hp-widget>

    <v-tooltip
      v-model="popupVisible"
      :position-x="popupPositionX"
      :position-y="popupPositionY"
      right
    >
      <span>{{ popupContent }}</span>
    </v-tooltip>
  </v-col>
</template>

<script>
import widgetMixin from '@/mixins/widgets';
import { mapGetters, mapActions } from 'vuex';
import { createEvent } from 'ics';

export default {
  name: 'Calendar',
  mixins: [widgetMixin],
  data: () => ({
    focus: new Date().toISOString(),
    type: 'month',
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    startDate: null,
    endDate: null,
    popupVisible: false,
    popupContent: '',
    popupPositionX: 0,
    popupPositionY: 0,
    colors: ['orange', 'pink lighten-2', 'red lighten-1', 'green'],
    icons: ['', '🎂'],
    events: []
  }),
  computed: {
    ...mapGetters({
      employees: 'employees/getEmployees',
      updated: 'dashboard/getDatesUpdated'
    }),

    dateLabel() {
      var currentDate = new Date(this.focus);
      return (
        this.$t(`dashboard.months.${currentDate.getMonth() + 1}`) +
        ` ${currentDate.getFullYear()}`
      );
    },

    visibleEvents() {
      if (
        !this.startDate ||
        !this.endDate ||
        !this.calendarEvents ||
        !this.calendarEvents.length
      ) {
        return [];
      }

      const visibleEvents = this.calendarEvents.filter(
        (x) => x.start >= this.startDate && x.start <= this.endDate
      );

      const startYear = this.startDate.getFullYear();
      const endYear = this.endDate.getFullYear();
      const recurringEvents = this.calendarEvents.filter((x) => x.start < 0);
      for (const event of [...recurringEvents]) {
        event.start = new Date(event.start).setFullYear(startYear);
        if (event.start >= this.startDate || event.start <= this.endDate) {
          visibleEvents.push(event);
        } else if (endYear > startYear) {
          event.start = new Date(event.start).setFullYear(endYear);
          if (event.start >= this.startDate || event.start <= this.endDate) {
            visibleEvents.push(event);
          }
        }
      }

      return visibleEvents;
    },

    calendarEvents() {
      if (!this.events || !this.events.length) {
        return [];
      }

      return this.events.map((x) => ({
        name: this.getEventName(x.eventName, x.eventType),
        start: new Date(x.eventDate),
        type: x.eventType,
        timed: x.duration
      }));
    }
  },

  async mounted() {
    //await this.generateBirthdays();
    this.events = await this.getDates();
    this.$refs.calendar.checkChange();
  },

  watch: {
    async updated(value) {
      if (value) {
        this.events = await this.getDates();
      }
    }
  },

  methods: {
    ...mapActions({
      getDates: 'dashboard/getDates'
      //generateBirthdays: 'dashboard/generateBirthdays'
    }),
    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },
    getEventName(eventName, eventType) {
      const name = this.localize(eventName);
      const icon = this.icons[eventType];
      if (!icon) {
        return name;
      }

      return icon + ' ' + name;
    },
    getEventColor(event) {
      return this.colors[event.type];
    },
    setToday() {
      this.focus = '';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    async exportDate({ nativeEvent, event }) {
      nativeEvent.stopPropagation();
      const icsEvent = {
        title: event.name,
        start: event.start,
        end: event.end
      };

      const filename = icsEvent.title + '.ics';
      const file = await new Promise((resolve, reject) => {
        createEvent(icsEvent, (error, value) => {
          if (error) {
            reject(error);
          }

          resolve(new File([value], filename, { type: 'text/calendar' }));
        });
      });

      const url = URL.createObjectURL(file);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = filename;
      anchor.click();
      URL.revokeObjectURL(url);
    },

    openEventPopup({ nativeEvent, event }) {
      nativeEvent.stopPropagation();
      this.popupPositionX = nativeEvent.clientX;
      this.popupPositionY = nativeEvent.clientY;
      this.popupContent = event.name;
      this.popupVisible = true;
    },

    closeEventPopup({ nativeEvent }) {
      nativeEvent.stopPropagation();
      this.popupVisible = false;
    },

    weekday(date) {
      return this.$t(`dashboard.daysShort.${date.weekday + 1}`);
    },

    month(date) {
      return this.$t(`dashboard.monthsShort.${date.month}`);
    },

    updateRange({ start, end }) {
      this.startDate = new Date(`${start.date}T00:00:00`);
      this.endDate = new Date(`${end.date}T23:59:59`);
    }
  }
};
</script>

<style></style>
