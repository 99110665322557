<template>
  <div class="dashboard">
    <draggable
      v-model="widgets"
      :options="{
        animation: 100,
        handle: '.drag-widget'
      }"
      @end="updateIndex"
    >
      <transition-group name="fade" class="row">
        <template v-for="widget in availableWidgets">
          <component
            @closed="closeWidget"
            @resized="resizeWidget"
            class="dashboard-widget"
            :is="widget.type"
            v-if="widget.active"
            :key="widget.name"
            :size="widget.size"
            :processType="widget.processType"
          />
        </template>
      </transition-group>
    </draggable>

    <v-dialog v-model="dialog" width="400">
      <template v-slot:activator="{ on }">
        <v-fab-transition>
          <hp-button primary fixed bottom right fab button :on="on">
            <v-icon>mdi-plus</v-icon>
          </hp-button>
        </v-fab-transition>
      </template>

      <v-card>
        <v-card-title class="text-h5 lighten-2">
          {{ $t('dashboard.addWidget') }}
        </v-card-title>

        <v-divider></v-divider>

        <v-list nav dense>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="widget in availableWidgets.filter((x) => !x.active)"
              :key="widget.name"
              @click="addWidget(widget)"
            >
              <v-list-item-icon>
                <v-icon>
                  {{ widget.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ widget.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <template v-if="!showAddWidget">
            <div class="muted text-center mb-4">
              {{ $t('dashboard.noWidgetsAvailable') }}
            </div>
          </template>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters, mapActions } from 'vuex';

// @ is an alias to /src
import todos from '@/components/widgets/Todos';
import calendar from '@/components/widgets/Calendar';
import recentOnboardings from '@/components/widgets/RecentOnboardings';

export default {
  name: 'Dashboard',

  components: {
    todos,
    calendar,
    recentOnboardings,
    draggable
  },

  data() {
    return {
      dialog: false,
      widgets: [],
      standardWidgets: [
        {
          name: 'Termine',
          icon: 'mdi-calendar',
          type: 'calendar',
          active: true,
          initializeAsActive: false,
          index: 1,
          size: {
            sm: 12,
            md: 8
          }
        },
        {
          name: 'Aufgaben',
          icon: 'mdi-format-list-checks',
          type: 'todos',
          active: true,
          initializeAsActive: false,
          index: 2,
          size: {
            sm: 6,
            md: 4
          }
        },
        {
          name: 'Neue Mitarbeitende',
          icon: '',
          type: 'recentOnboardings',
          active: true,
          initializeAsActive: false,
          index: 3,
          size: {
            sm: 6,
            md: 4
          }
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    }),

    showAddWidget() {
      return this.availableWidgets.some((x) => !x.active);
    },

    availableWidgets() {
      if (!this.currentUser) {
        return [];
      }

      return this.widgets;
    }
  },

  async mounted() {
    const loadedWidgets = await this.loadWidgets();
    if (loadedWidgets && loadedWidgets.length > 0) {
      this.widgets = loadedWidgets;
      this.widgets.sort((x, y) => x.index - y.index);
      this.updateWidgets();
    } else {
      this.widgets = this.standardWidgets;
      this.saveWidgets(this.widgets);
    }
  },

  methods: {
    ...mapActions({
      loadWidgets: 'dashboard/getWidgets',
      saveWidgets: 'dashboard/saveWidgets'
    }),

    closeWidget(widgetType) {
      const properties = Object.getOwnPropertyNames(widgetType);
      const widget = this.widgets.find((x) =>
        properties.every((p) => x[p] === widgetType[p])
      );
      widget.active = false;
      this.widgets
        .filter((x) => x.index > widget.index)
        .forEach((x) => {
          x.index -= 1;
        });
      widget.index = -1;
      this.saveWidgets(this.widgets);
    },

    addWidget(widget) {
      this.dialog = false;
      widget.active = true;
      widget.index = this.widgets.filter((x) => x.active).length;
      this.saveWidgets(this.widgets);
      this.widgets.sort((x, y) => x.index - y.index);
    },

    resizeWidget(data) {
      const widgetType = data.type;
      const properties = Object.getOwnPropertyNames(widgetType);
      const widget = this.widgets.find((x) =>
        properties.every((p) => x[p] === widgetType[p])
      );
      widget.size = data.size;
      this.saveWidgets(this.widgets);
    },

    updateIndex() {
      for (var i = 0; i < this.widgets.length; i++) {
        this.widgets[i].index = i + 1;
      }
      this.saveWidgets(this.widgets);
    },

    updateWidgets() {
      var save = false;
      this.widgets = this.widgets.filter((widget) => {
        const found = this.standardWidgets.find((x) =>
          this.compareWidgets(widget, x)
        );
        save ||= found === undefined;
        return found;
      });

      for (const widget of this.standardWidgets) {
        if (!this.widgets.find((x) => this.compareWidgets(widget, x))) {
          let widgetIndex = -1;
          if (widget.initializeAsActive) {
            widgetIndex = this.widgets.filter((x) => x.active).length;
          }

          widget.active = widget.initializeAsActive;
          widget.index = widgetIndex;
          this.widgets.push(widget);
          save = true;
        }
      }

      if (save) {
        this.saveWidgets(this.widgets);
      }
    },

    compareWidgets(a, b) {
      return a.name === b.name && a.type === b.type && a.icon === b.icon;
    }
  }
};
</script>

<style>
.dashboard-widget .v-list-item {
  min-height: 75px;
}

.drag-widget {
  cursor: move;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.fade-slow-enter-active,
.fade-slow-leave-active {
  transition: all 0.8s;
}

.fade-slow-enter,
.fade-slow-leave-active {
  opacity: 0;
}

.fade-fast-enter-active,
.fade-fast-leave-active {
  transition: all 0.2s;
}

.fade-fast-enter,
.fade-fast-leave-active {
  opacity: 0;
}

.dashboard .v-card.v-card--flat {
  height: 100%;
}
</style>
