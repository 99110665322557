<template>
  <div>
    <template v-if="readonly"> {{ text }} </template>
    <v-text-field v-else v-model="text" v-bind="$attrs" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },

    readonly: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      text: ''
    };
  },

  watch: {
    text() {
      this.$emit('input', this.text);
    },

    value() {
      this.text = this.value;
    }
  },

  mounted() {
    if (this.value) {
      this.text = this.value;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep button.v-btn {
  padding: 0 6px;
  min-width: 40px;
}
</style>
