<template>
  <v-col cols="12" :sm="currentSize.sm" :md="currentSize.md">
    <hp-widget icon="mdi-calendar">
      <template v-slot:title>
        {{ $t('dashboard.widgets.recentOnboardings.title') }} ({{
          items.length
        }})
      </template>

      <template v-slot:actions>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <hp-button
              small
              plain
              icon
              @click="resize({ type: 'recentOnboardings' })"
              v-on="on"
            >
              <v-icon>mdi-arrow-expand-horizontal</v-icon>
            </hp-button>
          </template>

          <span>{{ $t('dashboard.resize') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <hp-button small plain icon class="drag-widget" v-on="on">
              <v-icon>mdi-arrow-expand-all</v-icon>
            </hp-button>
          </template>

          <span>{{ $t('dashboard.move') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <hp-button
              small
              plain
              icon
              @click="close({ type: 'recentOnboardings' })"
              v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </hp-button>
          </template>

          <span>{{ $t('dashboard.remove') }}</span>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item-group v-if="loading">
          <v-list-item>
            <v-list-item-content>
              <v-progress-circular
                indeterminate
                color="secondary"
              ></v-progress-circular>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group v-else-if="items && items.length > 0">
          <template v-for="(item, index) in items">
            <v-list-item :key="item.id" @click="goToTask(item)">
              <template>
                <v-list-item-content>
                  <v-list-item-title style="font-weight: bold">
                    {{ item.employeeName }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-if="getStartDate(item)"
                    :class="getClass(item)"
                  >
                    {{
                      getStartDate(item).toLocaleDateString(
                        languageKey,
                        dateFormat
                      )
                    }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="getTimeUntilStart(item)"
                    :class="getClass(item)"
                  >
                    {{ getTimeUntilStart(item) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-avatar size="50">
                    <user-image v-model="item.picture" />
                  </v-avatar>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-divider :key="index"></v-divider>
          </template>
        </v-list-item-group>
        <v-list-item-group v-else>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{
                  $t('dashboard.widgets.recentOnboardings.noRecentOnboardings')
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </hp-widget>
  </v-col>
</template>

<script>
import widgetMixin from '@/mixins/widgets';
import userImage from '@/components/misc/user-image';
import { mapGetters } from 'vuex';

export default {
  name: 'RecentOnboardings',
  mixins: [widgetMixin],
  data: () => ({
    loading: true
  }),

  components: {
    userImage
  },

  computed: {
    ...mapGetters({
      employees: 'employees/getEmployees',
      employeesLoaded: 'employees/getEmployeesLoaded',
      languageKey: 'languageKey',
      dateFormat: 'dateFormat'
    }),

    items() {
      if (this.loading) {
        return [];
      }

      const items = [...this.employees?.filter((x) => x.onboarding)] ?? [];
      for (const item of items) {
        item.daysUntilStart = this.getDaysUntilStart(item);
        item.employeeName = item.firstName + ' ' + item.lastName;
      }

      return items.sort((x, y) => {
        const dayDiff = x.daysUntilStart - y.daysUntilStart;
        return dayDiff ? dayDiff : x.employeeName.localeCompare(y.employeeName);
      });
    }
  },

  watch: {
    employeesLoaded() {
      this.loading = !this.employeesLoaded;
    }
  },

  async mounted() {
    this.loading = !this.employeesLoaded;
  },

  methods: {
    goToTask(item) {
      this.$router.push({
        name: 'EmployeeDetails',
        params: { id: item.id }
      });
    },

    getStartDate(item) {
      const startDateInfo = item.customInformation?.find(
        (x) => x.mappingType === 2000
      );

      if (!startDateInfo?.value) {
        return null;
      }

      const startDate = JSON.parse(startDateInfo.value);
      if (!startDate) {
        return null;
      }

      return new Date(new Date(startDate).setHours(0, 0, 0, 0));
    },

    getDaysUntilStart(item) {
      const startDate = this.getStartDate(item);
      if (!startDate) {
        return NaN;
      }

      const currentDate = new Date().setHours(0, 0, 0, 0);
      const msUntil = startDate - currentDate;
      return Math.floor(msUntil / (24 * 60 * 60 * 1000));
    },

    getTimeUntilStart(item) {
      const daysUntil = this.getDaysUntilStart(item);
      if (isNaN(daysUntil)) {
        return '';
      }

      if (daysUntil > 0) {
        return this.$t('dashboard.inDays', { count: daysUntil });
      }
      if (daysUntil < 0) {
        return this.$t('dashboard.daysAgo', { count: -daysUntil });
      }

      return this.$t('dashboard.today');
    },

    getClass(item) {
      const daysUntil = this.getDaysUntilStart(item);
      if (daysUntil < 0) {
        return 'late';
      } else if (daysUntil < 3) {
        return 'soon';
      }

      return '';
    }
  }
};
</script>
<style scoped>
.v-list-item__subtitle.soon {
  color: orange !important;
}
.v-list-item__subtitle.late {
  color: red !important;
}
</style>
