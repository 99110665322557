<template>
  <div class="employees">
    <hp-card class="hero-bar">
      <v-tabs v-model="currentTab" align-with-title grow>
        <v-tabs-slider color="var(--v-primary-base)" />
        <v-tab> {{ $t('statistics.reportTab') }} </v-tab>
        <v-tab disabled> {{ $t('statistics.name') }} </v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab">
        <v-tab-item>
          <v-card flat class="mt-1 py-8">
            <v-card-text>
              <v-row>
                <v-col>
                  {{ $t('statistics.reportInfoIntro') }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  {{ $t('statistics.reportInfoOutro') }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="9" class="d-flex align-center text-center">
                  <hp-button
                    @click="createUserReport"
                    prepend-icon="mdi-file-excel"
                  >
                    {{ $t('statistics.reportDownload') }}
                  </hp-button>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </hp-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Statistics',

  data() {
    return {
      currentTab: null
    };
  },

  methods: {
    ...mapActions({
      createReport: 'employees/createReport'
    }),

    async createUserReport() {
      const data = await this.createReport();
      const fileName = 'report.xlsx';
      const file = new File([data], fileName);
      const linkElement = document.createElement('a');
      linkElement.href = URL.createObjectURL(file);
      linkElement.download = fileName;
      linkElement.click();
      URL.revokeObjectURL(linkElement.href);
    }
  }
};
</script>
<style scoped>
.row {
  padding-bottom: 12px;
}
</style>
