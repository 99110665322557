<template>
  <api-image :bucket="imageBucket" :file-name="imageFileName" />
</template>

<script>
import apiImage from '@/components/misc/api-image';

export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: null
    }
  },

  components: {
    apiImage
  },

  computed: {
    pictureObj() {
      const image = this.value?.filePath;
      if (image && image.indexOf('{') >= 0 && image.indexOf('}') >= 0) {
        return JSON.parse(image);
      }

      return null;
    },

    imageFileName() {
      return this.pictureObj?.FileName ?? 'default_avatar.jpg';
    },

    imageBucket() {
      return this.pictureObj?.Bucket ?? 'public';
    }
  }
};
</script>
